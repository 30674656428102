import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import {
  keepMaftir2,
  sendMaftir2,
  keepParasha2,
  sendParasha2,
  sendEmail,
  keepName,
  loadUsers,
} from "../store/actions/userAction";
//import "./styles.css";
import ReactCalendar from "react-calendar";
import styled from "styled-components";
import Hebcal, { HDate } from "hebcal";
import gematriya from "gematriya";
import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";

export function ReadReservation() {
  const dispatch = useDispatch();
  const [openFlag, setOpenFlag] = useState(parseInt(0));
  const [data, setData] = useState([]);
  const [dateU, setDateU] = useState("");
  const [Ev, setEv] = useState("");
  const [bol, setBol] = useState(false);
  const [bol2, setBol2] = useState(false);
  const [haftara, setHaftara] = useState("");

  //let bol = false;
  const [focusIdx, setFocusIdx] = useState(0);
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerDetails, setCostumerDetails] = useState("");
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const users = useSelector((state) => state.userReducer.users);
  // setOpenFlag(0);
  useEffect(async () => {
    await dispatch(loadUsers());
    // setOpenFlag(0);
  }, [Ev]);

  function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }
  const clickParasha = async () => {
    if (openFlag === 2) {
      setOpenFlag(0);
      setBol(false);
      setBol2(false);
    } else {
      setData(await keepParasha2());
      //console.log("data", data);
      setOpenFlag(2);
      setBol(false);
      setBol2(false);
    }
  };
  const clickMaftir = async () => {
    if (openFlag === 1) {
      setOpenFlag(0);
      setBol(false);
      setBol2(false);
    } else {
      setData(await keepMaftir2());
      //console.log("data", data);
      setOpenFlag(1);
      setBol(false);
      setBol2(false);
    }
  };
  const Calendar = styled(ReactCalendar)`
    width: 70%;
    margin: auto;

    text-align: center;

    abbr {
      color: rgb(0, 0, 0);
    }
    .react-calendar__month-view__weekdays__weekday {
      text-align: center;
      background: rgb(125, 125, 66);
    }
    .react-calendar__navigation__label {
      height: 40px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
      width: 20px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
      width: 30px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      width: 20px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
      width: 30px;
    }
    .react-calendar__month-view {
      .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.3;
      }
      .free {
        background-color: green;
      }
      .week {
        background-color: white;
      }
      .react-calendar__tile--active {
        border: 2px solid blue;
        z-index: 100;
      }
      .busy {
        background-color: red;
      }
      .onAttent {
        background-color: yellow;
      }
      .react-calendar__tile {
        height: 50px;
        position: relative;

        &--now {
          border: 2px solid red;
          z-index: 100;
        }

        abbr {
          display: none;
          font-size: 1em;
        }

        .tile {
          &__hdate {
            position: absolute;
            top: 0;
            right: 0;
          }

          &__daf {
            font-size: 1.5em;
          }
        }
      }
    }
  `;

  const defTileClassName = ({ date }) => {
    let a = 0;
    if (date.getDay() === 6) {
      //  console.log("date", moment(date).format("DD/MM/YYYY"));
      data.map((ev) => {
        // console.log(ev.date);
        if (ev.date === moment(date).format("DD/MM/YYYY")) {
          if (ev.status === 1) {
            //console.log("helloo");
            a = 1;
            //console.log("helloo");
          } else if (ev.status === 2) {
            // console.log("helloo");
            // console.log(data);
            a = 2;
          }
        }
      });
      if (a === 1) return "busy";
      if (a === 2) return "onAttent";

      return "free";
    } else return "week";
  };
  const checkDate = (newDate) => {
    setBol(false);
    setBol2(false);
    setEv("");
    setDateU(newDate);
    setHaftara(
      Hebcal.HDate(newDate).setLocation(32.081216, 34.851012).getSedra("h")
    );

    if (newDate.getDay() === 6) {
      setBol(true);
      //console.log(newDate);

      data.map((ev) => {
        if (ev.date === moment(newDate).format("DD/MM/YYYY")) {
          // console.log("yessss");
          setEv(ev);
        }
      });
    }
    //  console.log("Ev=", Ev);
  };

  const submit = () => {
    console.log("costumerLastName", costumerLastName);
    if (costumerLastName === undefined) {
      alert("merci d'indiquer le nom du lecteur");
    } else {
      if (openFlag === 1) {
        const newobj = {
          submiterId: loggedInUser._id,
          submiterName: loggedInUser.firstName + " " + loggedInUser.lastName,
          reader: costumerLastName,
          date: moment(dateU).format("DD/MM/YYYY"),
          status: 2,
        };
        data.push(newobj);
        sendMaftir2(data);
        sendEmail({
          to: "betyossefhaim.gs@gmail.com",
          subject: "demande de lecture de Haftara",
          text:
            loggedInUser.firstName +
            " " +
            loggedInUser.lastName +
            " a demandé une lecture de la haftara de " +
            haftara +
            " le " +
            moment(dateU).format("DD/MM/YYYY") +
            " , merci de valider ou non",
        });
        sendEmail({
          to: loggedInUser.email,
          subject: "demande de lecture de Hafara",
          text:
            "bonjour " +
            loggedInUser.firstName +
            " " +
            loggedInUser.lastName +
            " votre demande de lecture de Haftara du shabbat de " +
            haftara +
            " le " +
            moment(dateU).format("DD/MM/YYYY") +
            " a bien été enregistrée , vous receverez prochainnement un mail de validation ou refus du gestionnaire",
        });
        setBol2(false);
        setBol(false);
      }
      if (openFlag === 2) {
        if (costumerDetails === undefined) {
          alert(
            "merci d'indiquer les numero de montees souhaitees , max 3 montees"
          );
        } else {
          const newobj = {
            submiterId: loggedInUser._id,
            submiterName: loggedInUser.firstName + " " + loggedInUser.lastName,
            reader: costumerLastName,
            date: moment(dateU).format("DD/MM/YYYY"),
            details: costumerDetails,
            status: 2,
          };
          data.push(newobj);
          sendParasha2(data);
          sendEmail({
            to: "betyossefhaim.gs@gmail.com",
            subject: "demande de lecture de Parasha",
            text:
              loggedInUser.firstName +
              " " +
              loggedInUser.lastName +
              " a demandé une lecture de la Parasha de " +
              haftara +
              " le " +
              moment(dateU).format("DD/MM/YYYY") +
              " , merci de valider ou non",
          });
          sendEmail({
            to: loggedInUser.email,
            subject: "demande de lecture de Parasha",
            text:
              "bonjour " +
              loggedInUser.firstName +
              " " +
              loggedInUser.lastName +
              " votre demande de lecture de Parasha du shabbat de " +
              haftara +
              " le " +
              moment(dateU).format("DD/MM/YYYY") +
              " a bien été enregistrée , vous receverez prochainnement un mail de validation ou refus du gestionnaire",
          });
          setBol2(false);
          setBol(false);
        }
      }
    }
  };

  const accept = () => {
    let a = data.findIndex(
      (el) => el.date === moment(dateU).format("DD/MM/YYYY")
    );
    if (openFlag === 1) {
      const newobj = {
        submiterId: data[a].submiterId,
        submiterName: data[a].submiterName,
        reader: data[a].reader,
        date: data[a].date,
        status: 1,
      };
      users.map((util) => {
        if (util._id === data[a].submiterId) {
          sendEmail({
            to: util.email,
            subject: "votre demande de lecture de Haftara",
            text:
              "bonjour " +
              data[a].submiterName +
              " votre demande de lecture de Haftara le " +
              data[a].date +
              " a été validé",
          });
        }
      });
      data.splice(a, 1, newobj);
      sendMaftir2(data);
      setBol2(false);
      setBol(false);
    } else {
      const newobj = {
        submiterId: data[a].submiterId,
        submiterName: data[a].submiterName,
        reader: data[a].reader,
        date: data[a].date,
        details: data[a].details,
        status: 1,
      };
      users.map((util) => {
        if (util._id === data[a].submiterId) {
          sendEmail({
            to: util.email,
            subject: "votre demande de lecture de Parasha",
            text:
              "bonjour " +
              data[a].submiterName +
              " votre demande de lecture de Parasha le " +
              data[a].date +
              " a été validé",
          });
        }
      });
      data.splice(a, 1, newobj);
      sendParasha2(data);
      setBol2(false);
      setBol(false);
    }
  };

  const deleteU = () => {
    let a = data.findIndex(
      (el) => el.date === moment(dateU).format("DD/MM/YYYY")
    );

    if (openFlag === 1) {
      users.map((util) => {
        if (util._id === data[a].submiterId) {
          sendEmail({
            to: util.email,
            subject: "votre demande de lecture de Haftara",
            text:
              "bonjour " +
              data[a].submiterName +
              " votre demande de lecture de Haftara le " +
              data[a].date +
              " a été annulé",
          });
        }
      });
      data.splice(a, 1);
      sendMaftir2(data);
    } else {
      users.map((util) => {
        if (util._id === data[a].submiterId) {
          sendEmail({
            to: util.email,
            subject: "votre demande de lecture de Parasha",
            text:
              "bonjour " +
              data[a].submiterName +
              " votre demande de lecture de Parasha le " +
              data[a].date +
              " a été annulé",
          });
        }
      });
      data.splice(a, 1);
      sendParasha2(data);
    }
    setBol2(false);
    setBol(false);
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;
    // console.log(ev);

    setFocusIdx(1);
  };
  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "costumerLastName":
        setCostumerLastName(value);
        break;
      case "costumerDetails":
        setCostumerDetails(value);
        break;

      default:
        break;
    }
  };
  const DateTile = ({ activeStartDate, date, view }) => {
    //Hebcal.HDate.prototype.setLocation(32.083, 34.766);
    const hDate = Hebcal.HDate(date).setLocation(32.081216, 34.851012);
    // console.log(gematriya(hDate.getDate()));

    const daf = hDate.dafyomi();
    const title =
      date.toDateString().slice(3) + "\n" + hDate.toString("h") + "\n" + daf;

    // const gDate = date.getDate();

    return (
      view === "month" && (
        <div title={title}>
          <div
            css={`
              text-align: center;
            `}
          >
            {date.getDate()} &middot;
            {gematriya(hDate.getDate())}
          </div>
          <div className="tile__daf">
            {date.getDay() === 6 && hDate.isSedra() ? (
              hDate.getSedra("h")
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <div>
            {date.getDay() === 5 ? (
              hDate.candleLighting().toLocaleTimeString()
            ) : // <>&nbsp;</>
            date.getDay() === 6 && hDate.isSedra() ? (
              hDate.getSedra("h")
            ) : (
              <>&nbsp;</>
            )}
          </div>
        </div>
      )
    );
  };

  //function App() {
  if (!loggedInUser || !loggedInUser.isApprouved)
    return (
      <div>
        <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
        <h3>
          {" "}
          vous n'avez pas encore été approuvé par le responsable de
          l'application, patience ...{" "}
        </h3>
      </div>
    );
  return (
    <div>
      <div>
        {openFlag !== 2 && (
          <button className="btn animation-on-hover" onClick={clickMaftir}>
            reserver une Haftara
          </button>
        )}
        {openFlag !== 1 && (
          <button className="btn animation-on-hover" onClick={clickParasha}>
            reserver une lecture de Parasha
          </button>
        )}
      </div>
      <div>
        {bol &&
          (bol2 ? (
            <div>
              <p>
                <br></br>
              </p>
              <TextField
                id="outlined-read-only-input"
                label="Demandeur"
                defaultValue={
                  Ev.status === 1 || Ev.status === 2
                    ? Ev.submiterName
                    : loggedInUser.firstName + " " + loggedInUser.lastName
                }
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="outlined-read-only-input"
                label="date"
                defaultValue={moment(dateU).format("DD/MM/YYYY")}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                /*id="outlined-read-only-input"*/
                label={openFlag === 1 ? "haftara" : "parasha"}
                defaultValue={haftara}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Nom du lecteur"
                className="uyu"
                InputProps={
                  Ev.status === 1
                    ? loggedInUser.isManager ||
                      loggedInUser._id === Ev.submiterId
                      ? { readOnly: false }
                      : {
                          readOnly: true,
                        }
                    : Ev.status === 2
                    ? loggedInUser.isManager
                      ? { readOnly: false }
                      : {
                          readOnly: true,
                        }
                    : {
                        readOnly: false,
                      }
                }
                onFocus={onChangeFocus}
                type="text"
                name="costumerLastName"
                onChange={onHandleInputChange}
                value={costumerLastName}
              />
              {openFlag === 2 && (
                <TextField
                  label="num montees(max 3) ex: 1,2,5"
                  className="uyu"
                  InputProps={
                    Ev.status === 1
                      ? loggedInUser.isManager ||
                        loggedInUser._id === Ev.submiterId
                        ? { readOnly: false }
                        : {
                            readOnly: true,
                          }
                      : Ev.status === 2
                      ? loggedInUser.isManager
                        ? { readOnly: false }
                        : {
                            readOnly: true,
                          }
                      : {
                          readOnly: false,
                        }
                  }
                  onFocus={onChangeFocus}
                  type="text"
                  name="costumerDetails"
                  onChange={onHandleInputChange}
                  value={costumerDetails}
                />
              )}
              {!(Ev.status === 1) && !(Ev.status === 2) && (
                <Button
                  className="save-btn btn-simple animation-on-hover"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    submit();
                  }}
                >
                  soumettre
                </Button>
              )}
              {Ev.status === 1 &&
                (loggedInUser.isManager ||
                  loggedInUser._id === Ev.submiterId) && (
                  <Button
                    className="save-btn btn-simple animation-on-hover"
                    color="danger"
                    variant="contained"
                    onClick={() => {
                      deleteU();
                    }}
                  >
                    supprimer
                  </Button>
                )}
              {Ev.status === 2 && loggedInUser.isManager && (
                <Button
                  className="save-btn btn-simple animation-on-hover"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    accept();
                  }}
                >
                  accepter
                </Button>
              )}
              {Ev.status === 2 &&
                (loggedInUser.isManager ||
                  loggedInUser._id === Ev.submiterId) && (
                  <Button
                    className="save-btn btn-simple animation-on-hover"
                    color="danger"
                    variant="contained"
                    onClick={() => {
                      deleteU();
                    }}
                  >
                    supprimer
                  </Button>
                )}
            </div>
          ) : (
            <div>
              <p>
                <br></br>
              </p>
              <Button
                className="save-btn btn-simple animation-on-hover"
                color="success"
                variant="contained"
                onClick={() => {
                  setBol2(true);
                  setCostumerLastName(Ev.reader);
                  setCostumerDetails(Ev.details);
                }}
              >
                details
              </Button>
            </div>
          ))}
      </div>
      <div>
        {openFlag === 1 && (
          <Calendar
            calendarType="Hebrew"
            maxDate={addMonths(new Date(), 6)}
            onChange={checkDate}
            value={dateU}
            tileContent={DateTile}
            tileClassName={defTileClassName}
            showFixedNumberOfWeeks
            showNeighboringMonth={true}
            formatShortWeekday={(locale, date) => date.toDateString()[0]}
          />
        )}
        {openFlag === 2 && (
          <Calendar
            calendarType="Hebrew"
            maxDate={addMonths(new Date(), 6)}
            onChange={checkDate}
            value={dateU}
            tileContent={DateTile}
            tileClassName={defTileClassName}
            showFixedNumberOfWeeks
            showNeighboringMonth={true}
            formatShortWeekday={(locale, date) => date.toDateString()[0]}
          />
        )}
      </div>
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
