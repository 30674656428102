import React, { useState, useEffect } from "react";
import { Paper, Avatar } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { loadUsers, removeUser, saveUser } from "../store/actions/userAction";
import { loadCrms, setFilterCrm } from "../store/actions/crmAction";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import moment from "moment";
import {
  Menu,
  MenuItem,
  Switch,
  Checkbox,
  CheckBoxOutlineBlankIcon,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Oval } from "react-loading-icons";
import classnames from "classnames";
import { DataImgsHeader } from "./DataImgsHeader";
import { DateRange } from "./DateRange";
import { DataImgsHeaderEvennements } from "./DataImgsHeaderEvennements";

export function DashboardHeaderEvennements({
  crmCount,
  onAdd,
  crmId2,
  detailEv,
  onDelete,
  onlist,
  onParticipe,
  switched,
  handleChangeSwitch,
  statusDeverse,
  linkPay,
  afficheList,
  onDeverse,
  dateEv,
  onEdit,
  retour,
  titleEv,
}) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [anchorEl, setAnchorEl] = useState(null);

  const [focus, setFocus] = useState(false);
  const users = useSelector((state) => state.userReducer.users);

  const handleGlobalFilterChange = (ev) => {
    const { value } = ev.target;
    const globFilterObj = {
      all: value,
    };
    dispatch(setFilterCrm(globFilterObj));
    //console.log(globFilterObj);
    dispatch(loadCrms(globFilterObj));
  };
  if (crmId2 !== "")
    return (
      <Paper elevation={20}>
        <div className="dashboard-header-container flex-col ">
          <div className="dash-header-box1 flex">
            <div className="logo-container flex-col">
              {/*  <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="" />*/}
              <h1>{titleEv}</h1>
              <h2>{detailEv}</h2>
              <h3>{moment(dateEv).format("DD/MM/YYYY")}</h3>
            </div>
          </div>
          <div className="dash-header-box flex">
            {" "}
            {loggedInUser.isManager ? (
              <div>
                <Button
                  className="btn-add"
                  color="info"
                  variant="contained"
                  onClick={onEdit}
                >
                  <i class="fa fa-magic"></i> | editer
                </Button>{" "}
              </div>
            ) : null}
            <div>
              <Button
                className="btn-add"
                color="info"
                variant="contained"
                onClick={retour}
              >
                <i class="fa fa-reply"></i> | retour
              </Button>{" "}
            </div>
            {linkPay !== "" ? (
              <a target="_blank" href={linkPay}>
                <Button
                  className=" btn-add"
                  color="info"
                  variant="contained"
                  onClick={() => {
                    " ";
                  }}
                >
                  <i class=" fa fa-credit-card"></i> | payer (payBox)
                </Button>
              </a>
            ) : null}
            {loggedInUser.isManager ? (
              <div>
                <Button
                  className="btn-add"
                  color="info"
                  variant="contained"
                  onClick={onlist}
                >
                  <i class="fa fa-newspaper"></i> | lister
                </Button>{" "}
              </div>
            ) : null}
            {!statusDeverse ? (
              <div>
                <Button
                  className="btn-add"
                  color="info"
                  variant="contained"
                  onClick={onParticipe}
                >
                  <i class="fa fa-hand-pointer"></i> | Participer
                </Button>{" "}
              </div>
            ) : null}
            {!statusDeverse ? (
              <div
                className="switchButtonActif"
                hidden={loggedInUser.isManager ? false : true}
              >
                <label htmlFor="switch1">Actif</label>
                <Switch
                  defaultChecked={switched}
                  name="switch1"
                  color="primary"
                  onChange={handleChangeSwitch}
                ></Switch>
              </div>
            ) : null}
            {loggedInUser.isManager ? (
              <div>
                <Button
                  className="btn-add"
                  color="danger"
                  variant="contained"
                  onClick={onDelete}
                >
                  <i class="fa fa-trash"></i> | supprimer
                </Button>{" "}
              </div>
            ) : null}
            {loggedInUser.isManager && !statusDeverse ? (
              <div>
                <Button
                  className="btn-add"
                  color="danger"
                  variant="contained"
                  onClick={onDeverse}
                >
                  <i class="fa fa-lock"></i> | Deverser
                </Button>{" "}
              </div>
            ) : null}
            {loggedInUser.isManager && statusDeverse ? (
              <div>
                <Button
                  className="btn-add"
                  color="danger"
                  variant="contained"
                  onClick={onDeverse}
                >
                  <i class="fa fa-recycle"></i> | Reinitialiser
                </Button>{" "}
              </div>
            ) : null}
            {afficheList ? (
              <ReactHTMLTableToExcel
                color="info"
                id="test-table-xls-button"
                className="btn-export-container"
                table="table"
                filename="DashboardXls"
                sheet="tablexls"
                buttonText="Export Excel"
              />
            ) : null}
          </div>
        </div>
      </Paper>
    );

  if (!loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
  return (
    <Paper elevation={20}>
      <div className="dashboard-header-container flex-col ">
        <div className="dash-header-box1 flex">
          <div className="logo-container flex-col">
            {/*  <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="" />*/}
            <h1>Evennements</h1>
            <DataImgsHeaderEvennements crmCount={crmCount} />{" "}
          </div>
        </div>
        <div className="dash-header-box flex">
          {" "}
          {loggedInUser.isManager ? (
            <div>
              <Button
                className="btn-add"
                color="info"
                variant="contained"
                onClick={onAdd}
              >
                Nouvel Evennement
              </Button>{" "}
            </div>
          ) : null}
          {/*
          {loggedInUser.isManager ? (
            <div>
              <InputGroup
                className={`filterBy-input global-search-input ${classnames({
                  "input-group-focus": focus,
                })}`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="Rechercher"
                  onFocus={(e) => setFocus(true)}
                  onBlur={(e) => setFocus(false)}
                  name="partner"
                  onChange={handleGlobalFilterChange}
                  className="global-search-inputText"
                />
              </InputGroup>
            </div>
              ) : null}*/}
        </div>
      </div>
    </Paper>
  );
}
