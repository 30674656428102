import { crmService } from "../../services/crmService";

export function loadCrms(filterBy) {
  return async (dispatch) => {
    const crms = await crmService.query(filterBy);
    dispatch({ type: "SET_CRMS", crms });
  };
}

export function removeCrm(crmId) {
  return async (dispatch) => {
    await crmService.remove(crmId);
    dispatch({ type: "REMOVE_CRM", crmId });
  };
}

export function saveCrm(crm) {
  return async (dispatch) => {
    const actionType = crm._id ? "UPDATE_CRM" : "ADD_CRM";
    crm = await crmService.save(crm);
    dispatch({ type: actionType, crm });
  };
}

export function setEditCrm(crmIdxEdited) {
  return async (dispatch) => {
    dispatch({ type: "SET_CRMIDXEDITED", crmIdxEdited });
  };
}

export function setEditCrmSoldCred(crmsSoldCred) {
  return async (dispatch) => {
    dispatch({ type: "SET_CRMSSCRED", crmsSoldCred });
  };
}
export function setEditCrmSoldDeb(crmsSoldDeb) {
  return async (dispatch) => {
    dispatch({ type: "SET_CRMSSDEB", crmsSoldDeb });
  };
}

export function setEditCrmuSoldCred(crmUSoldCred) {
  return async (dispatch) => {
    dispatch({ type: "SET_CRMSCRED", crmUSoldCred });
  };
}
export function setEditCrmuSoldDeb(crmUSoldDeb) {
  return async (dispatch) => {
    dispatch({ type: "SET_CRMSDEB", crmUSoldDeb });
  };
}
export function setFilterCrm(filterCrm) {
  return (dispatch) => {
    dispatch({ type: "SET_FILTERCRM", filterCrm });
  };
}

export function clearFilterCrm() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_FILTERCRM" });
  };
}
