import React, { useState, useEffect } from "react";
import { eventBus } from "../services/eventBusService";
import { useDispatch, useSelector } from "react-redux";
import { crmService } from "../services/crmService";
import { saveCrm, setEditCrm } from "../store/actions/crmAction";
import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";

export function LineForm2({ closeModal, crmId }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);

  const [costumerFirstName, setCostumerFirstName] = useState("");
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerTelephone, setcostumerTelephone] = useState("");
  const [costumerSaisie, setcostumerSaisie] = useState([]);
  const [costumerDateMaj, setcostumerDateMaj] = useState("");
  const [costumerId, setcostumerId] = useState("");

  const [chosenBox1, setChosenBox1] = useState(0);
  const [chosenBox2, setChosenBox2] = useState(0);
  const [chosenBox3, setChosenBox3] = useState(0);
  const [chosenBox4, setChosenBox4] = useState(0);

  const [colorFlag1, setColorFlag1] = useState(false);
  const [colorFlag2, setColorFlag2] = useState(false);
  const [colorFlag3, setColorFlag3] = useState(false);
  const [colorFlag4, setColorFlag4] = useState(false);
  const [focusIdx, setFocusIdx] = useState(0);

  useEffect(() => {
    if (crmId) getDetailesByCrmId();
    //  console.log("crmid1", crmId);
  }, []);

  const getDetailesByCrmId = async () => {
    const data = await crmService.getById(crmId);
    console.log(data);

    setCostumerFirstName(data.firstName);
    setCostumerLastName(data.lastName);
    setcostumerTelephone(data.telephone);
    setcostumerSaisie(data.saisie);
    setcostumerDateMaj(data.dateMaj);
    setcostumerId(data._id);
  };

  /* const getCategory = (category) => {
    if (category === "partner" && chosenBox1 !== 1) {
      setChosenBox1(1);
      setColorFlag1(!colorFlag1);
    } else {
      setChosenBox1(0);
      setColorFlag1(false);
    }
    if (category === "mpr" && chosenBox2 !== 2) {
      setChosenBox2(2);
      setColorFlag2(!colorFlag2);
    } else {
      setChosenBox2(0);
      setColorFlag2(false);
    }
    if (category === "vertigo" && chosenBox3 !== 3) {
      setChosenBox3(3);
      setColorFlag3(!colorFlag3);
    } else {
      setChosenBox3(0);
      setColorFlag3(false);
    }
    if (category === "eco" && chosenBox4 !== 4) {
      setChosenBox4(4);
      setColorFlag4(!colorFlag4);
    } else {
      setChosenBox4(0);
      setColorFlag4(false);
    }
  };
*/
  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "costumerFirstName":
        setCostumerFirstName(value);
        break;
      case "costumerLastName":
        setCostumerLastName(value);
        break;
      case "costumerTelephone":
        setcostumerTelephone(value);
        break;

      default:
        break;
    }
  };

  const onFormSubmit = () => {
    const newCrmObj = {
      _id: costumerId ? costumerId : null,

      firstName: costumerFirstName,
      lastName: costumerLastName,
      telephone: costumerTelephone,
      saisie: costumerSaisie,
      dateMaj: moment().format(),
    };

    dispatch(saveCrm(newCrmObj));
    dispatch(setEditCrm(0));
    eventBus.emit("editing");
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;
    // console.log(ev);

    setFocusIdx(1);
  };

  if (!loggedInUser) return <div>loading</div>;

  return (
    <div className="line-big-container">
      <div className="line-form-container flex">
        <div className="section-container flex-col box1">
          <Button
            color="info"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>DONATEUR</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card flex-col">
            <TextField
              label="Nom du Donateur"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="costumerLastName"
              onChange={onHandleInputChange}
              value={costumerLastName}
            />
            <TextField
              label="Prénom du Donateur"
              onFocus={onChangeFocus}
              type="text"
              name="costumerFirstName"
              onChange={onHandleInputChange}
              value={costumerFirstName}
            />
            <TextField
              label="Numero de Telephone"
              onFocus={onChangeFocus}
              type="text"
              name="costumerTelephone"
              onChange={onHandleInputChange}
              value={costumerTelephone}
            />
            <div className="btn-modal-container">
              <Button
                className="btn-simple animation-on-hover close-modal-btn"
                color="danger"
                style={{ right: "0px" }}
                onClick={closeModal}
              >
                X
              </Button>
              <Button
                className="save-btn btn-simple animation-on-hover"
                color="success"
                variant="contained"
                onClick={onFormSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
