const initialState = {
  crms: [],
  crmIdxEdited: 0,
  crmsSoldDeb: "0",
  crmsSoldCred: "0",
  crmUSoldDeb: "0",
  crmUSoldCred: "0",
  filterCrm: "",
};

export function crmReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CRMS":
      return {
        ...state,
        crms: action.crms,
      };

    case "REMOVE_CRM":
      return {
        ...state,
        crms: state.crms.filter((crm) => crm._id !== action.crmId),
      };

    case "UPDATE_CRM":
      const idx = state.crms.findIndex((crm) => crm._id === action.crm._id);
      const updatedCrms = [...state.crms];
      updatedCrms[idx] = action.crm;
      return { ...state, crms: updatedCrms };

    case "ADD_CRM":
      return { crms: [...state.crms, action.crm], ...state };

    case "SET_CRMIDXEDITED":
      return {
        ...state,
        crmIdxEdited: action.crmIdxEdited,
      };
    case "SET_CRMSSCRED":
      return {
        ...state,
        crmsSoldCred: action.crmsSoldCred,
      };
    case "SET_CRMSSDEB":
      return {
        ...state,
        crmsSoldDeb: action.crmsSoldDeb,
      };
    case "SET_CRMSCRED":
      return {
        ...state,
        crmUSoldCred: action.crmUSoldCred,
      };
    case "SET_CRMSDEB":
      return {
        ...state,
        crmUSoldDeb: action.crmUSoldDeb,
      };

    case "SET_FILTERCRM":
      return { ...state, filterCrm: action.filterCrm };

    case "CLEAR_FILTERCRM":
      return { ...state, filterCrm: "" };
    default:
      return state;
  }
}
