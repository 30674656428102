import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Drawer, List, Divider } from "@material-ui/core";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import clsx from "clsx";
import moment from "moment";
import { loadCrms } from "../store/actions/crmAction";

const useStyles = makeStyles({
  list: {
    width: 400,
  },
  fullList: {
    width: "auto",
  },
});

export function DateRange() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDateInputChange = (ev) => {};

  const list = (anchor) => (
    <div
      className={
        (clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        }),
        "list-container")
      }
      role="presentation"
    >
      <List className="list2">
        <h4 className="date-serch-header">Search By DATE</h4>

        {/* <div className="inputs-date-container flex-col-between">
                    <div className="flex-between">
                        <label htmlFor="SoldeMpr">From</label>
                        <input className="date-range-input" type="date" name='SoldeMpr' onChange={handleDateInputChange} />
                    </div>

                    <div className="flex-between">
                        <label htmlFor="SoldeMpr">To</label>
                        <input className="date-range-input to-input-date" type="date" name='SoldeMpr' onChange={handleDateInputChange} />
                    </div>
                </div> */}
      </List>

      <Divider />
    </div>
  );

  return (
    <div className="date-range-container">
      <div>
        <Button onClick={toggleDrawer("right", true)}>Search By Date</Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </div>
    </div>
  );
}
