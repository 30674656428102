import React, { useState, useEffect } from "react";
import { eventBus } from "../services/eventBusService";
import { useDispatch, useSelector } from "react-redux";
import { crmService } from "../services/crmService";
import { saveCrm, setEditCrm } from "../store/actions/crmAction";
import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment, { now } from "moment";
import { saveUser } from "../store/actions/userAction";

export function LineForm8Evennement({ closeModal, crmId }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const users = useSelector((state) => state.userReducer.users);

  const [titleEv, setTitleEv] = useState("");
  const [detailEv, setDetailEv] = useState("");
  const [dateEv, setDateEv] = useState(moment().format("YYYY-MM-DD"));
  const [q1, setQ1] = useState("");
  const [priceQ1, setPriceQ1] = useState("");
  const [maxiQ1, setMaxiQ1] = useState("");
  const [typeQ1, setTypeQ1] = useState("text");
  const [q2, setQ2] = useState("");
  const [priceQ2, setPriceQ2] = useState("");
  const [maxiQ2, setMaxiQ2] = useState("");
  const [typeQ2, setTypeQ2] = useState("text");
  const [q3, setQ3] = useState("");
  const [priceQ3, setPriceQ3] = useState("");
  const [maxiQ3, setMaxiQ3] = useState("");
  const [typeQ3, setTypeQ3] = useState("text");
  const [q4, setQ4] = useState("");
  const [priceQ4, setPriceQ4] = useState("");
  const [maxiQ4, setMaxiQ4] = useState("");
  const [status, setStatus] = useState("Inactif");
  const [typeQ4, setTypeQ4] = useState("text");
  const [maxAffect, setMaxAffect] = useState("");
  const [linkPay, setLinkPay] = useState("");
  const [statusDeverse, setStatusDeverse] = useState(false);
  const [responses, setResponses] = useState([
    {
      rep1: "",
      rep2: "",
      rep3: "",
      rep4: "",
      repId: "",
      nameId: "TOTAL GENERAL",
      totalRep: "",
      dateRep: "",
    },
  ]);

  const [costumerFirstName, setCostumerFirstName] = useState("");
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerTelephone, setcostumerTelephone] = useState("");
  const [costumerSaisie, setcostumerSaisie] = useState([]);
  const [costumerDateMaj, setcostumerDateMaj] = useState("");
  const [costumerId, setcostumerId] = useState("");

  const [chosenBox1, setChosenBox1] = useState(0);
  const [chosenBox2, setChosenBox2] = useState(0);
  const [chosenBox3, setChosenBox3] = useState(0);
  const [chosenBox4, setChosenBox4] = useState(0);

  const [colorFlag1, setColorFlag1] = useState(false);
  const [colorFlag2, setColorFlag2] = useState(false);
  const [colorFlag3, setColorFlag3] = useState(false);
  const [colorFlag4, setColorFlag4] = useState(false);
  const [focusIdx, setFocusIdx] = useState(0);
  const [longTab, setLongTab] = useState(0);
  const [bolSave, setBolSave] = useState(false);
  //const [data, setData] = useState([]);
  const [userAdmin, setUserAdmin] = useState({});
  const [positionfF, setPositionfF] = useState(0);
  let data;
  let positionf;

  useEffect(() => {
    users.map((user) => {
      if (user.isAdmin) {
        setUserAdmin(user);
        try {
          setLongTab(
            Number(user.evennements[user.evennements.length - 1]._id) + 1
          );
        } catch (err) {
          setLongTab(1);
        }
        //setData(
        data = user.evennements.find((evennement) => evennement._id === crmId);
        positionf = user.evennements.findIndex(
          (evennement) => evennement._id === crmId
        );
        setPositionfF(positionf);
        //  console.log("positionf :", positionf);
        //);
      }
    });
    if (crmId) getDetailesByCrmId();
    // console.log("data0", data.firstName);
    //console.log("crmid1", crmId);
  }, []);

  const getDetailesByCrmId = () => {
    // console.log("data", data.firstName);
    setTitleEv(data.titleEv);
    setDetailEv(data.detailEv);
    setDateEv(data.dateEv);
    setQ1(data.q1);
    setPriceQ1(data.priceQ1);
    setTypeQ1(data.typeQ1);
    setMaxiQ1(data.maxiQ1);
    setQ2(data.q2);
    setPriceQ2(data.priceQ2);
    setTypeQ2(data.typeQ2);
    setMaxiQ2(data.maxiQ2);
    setQ3(data.q3);
    setPriceQ3(data.priceQ3);
    setTypeQ3(data.typeQ3);
    setMaxiQ3(data.maxiQ3);
    setQ4(data.q4);
    setPriceQ4(data.priceQ4);
    setTypeQ4(data.typeQ4);
    setMaxiQ4(data.maxiQ4);
    setMaxAffect(data.maxAffect);
    setLinkPay(data.linkPay);
    setResponses(data.responses);
    setStatus(data.status);
    setStatusDeverse(data.statusDeverse);

    // setCostumerFirstName(data.firstName);
    // setCostumerLastName(data.lastName);
    // setcostumerTelephone(data.telephone);
    //setcostumerSaisie(data.saisie);
    // setcostumerDateMaj(data.dateMaj);
    setcostumerId(data._id);
  };

  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "titleEv":
        setTitleEv(value);
        break;
      case "detailEv":
        setDetailEv(value);
        break;
      case "dateEv":
        setDateEv(value);
        break;
      case "maxAffect":
        setMaxAffect(value);
        break;
      case "linkPay":
        setLinkPay(value);
        break;
      case "q1":
        setQ1(value);
        break;
      case "priceQ1":
        setPriceQ1(value);
        break;
      case "typeQ1":
        setTypeQ1(value);
        break;
      case "maxiQ1":
        setMaxiQ1(value);
        break;

      case "q2":
        setQ2(value);
        break;
      case "priceQ2":
        setPriceQ2(value);
        break;
      case "typeQ2":
        setTypeQ2(value);
        break;
      case "maxiQ2":
        setMaxiQ2(value);
        break;
      case "q3":
        setQ3(value);
        break;
      case "priceQ3":
        setPriceQ3(value);
        break;
      case "typeQ3":
        setTypeQ3(value);
        break;
      case "maxiQ3":
        setMaxiQ3(value);
        break;
      case "q4":
        setQ4(value);
        break;
      case "priceQ4":
        setPriceQ4(value);
        break;
      case "typeQ4":
        setTypeQ4(value);
        break;
      case "maxiQ4":
        setMaxiQ4(value);
        break;

      default:
        break;
    }
    if (titleEv !== "" && detailEv !== "" && q1 !== "" && !statusDeverse) {
      setBolSave(true);
    } else {
      setBolSave(false);
    }
  };

  const onFormSubmit = () => {
    const newCrmObj = {
      _id: costumerId ? costumerId : longTab.toString(),
      titleEv: titleEv,
      detailEv: detailEv,
      dateEv: dateEv,
      status: status,
      maxAffect: maxAffect,
      linkPay: linkPay,
      q1: q1,
      priceQ1: priceQ1,
      typeQ1: typeQ1,
      maxiQ1: maxiQ1,
      q2: q2,
      priceQ2: priceQ2,
      typeQ2: typeQ2,
      maxiQ2: maxiQ2,
      q3: q3,
      priceQ3: priceQ3,
      typeQ3: typeQ3,
      maxiQ3: maxiQ3,
      q4: q4,
      priceQ4: priceQ4,
      typeQ4: typeQ4,
      maxiQ4: maxiQ4,
      responses: responses,
      statusDeverse: statusDeverse,
      //saisie: costumerSaisie,
      // dateMaj: moment().format(),
    };
    // console.log("userAdmin2", userAdmin);
    if (costumerId) {
      // console.log("positionf pour splice", positionfF);
      userAdmin.evennements.splice(positionfF, 1, newCrmObj);
    } else {
      userAdmin.evennements.push(newCrmObj);
    }

    dispatch(saveUser(userAdmin));
    dispatch(setEditCrm(0));
    eventBus.emit("editing");
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;
    //console.log(ev);

    setFocusIdx(1);
  };

  if (!loggedInUser) return <div>loading</div>;

  return (
    <div className="line-big-container">
      <div className="section-container3 ">
        <Button
          color="success"
          className="clicked choose-box animation-on-hover btn1"
        >
          <h5>Evennement</h5>
        </Button>
        <hr />
        <div className="inputs-card3 ">
          <div className="titleEv">
            <TextField
              fullWidth
              label="Titre de l'evennement"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="titleEv"
              onChange={onHandleInputChange}
              value={titleEv}
            />
          </div>
          <div className="DetailEv">
            <TextField
              fullWidth
              label="Detail de l'evennement"
              onFocus={onChangeFocus}
              multiline
              rows={3}
              type="text"
              name="detailEv"
              onChange={onHandleInputChange}
              value={detailEv}
            />
          </div>
          <TextField
            label="Date evennement"
            onFocus={onChangeFocus}
            type="date"
            name="dateEv"
            onChange={onHandleInputChange}
            value={dateEv}
          />
        </div>
      </div>
      <div className="line-form-container2 flex">
        <div className="section-container2 flex-col box1">
          <Button
            color="success"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>Question 1</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card2 flex-col">
            <TextField
              label="Question"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="q1"
              onChange={onHandleInputChange}
              value={q1}
            />
            <div className="flex-col">
              <label htmlFor="typeQ1">type de reponses</label>
              <select
                className="select1"
                required
                onFocus={onChangeFocus}
                name="typeQ1"
                onChange={onHandleInputChange}
                value={typeQ1}
              >
                <option value="text">Texte</option>
                <option value="number">Nombre Entier</option>
              </select>
            </div>
            <TextField
              label="Prix a affecter"
              onFocus={onChangeFocus}
              disabled={typeQ1 === "text" ? true : false}
              type="text"
              name="priceQ1"
              onChange={onHandleInputChange}
              value={priceQ1}
            />
            <TextField
              label="saisie maxi autorisee"
              onFocus={onChangeFocus}
              disabled={typeQ1 === "text" ? true : false}
              type="text"
              name="maxiQ1"
              onChange={onHandleInputChange}
              value={maxiQ1}
            />
          </div>
        </div>
        <div className="section-container2 flex-col box1">
          <Button
            color="success"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>Question 2</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card2 flex-col">
            <TextField
              label="Question"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="q2"
              onChange={onHandleInputChange}
              value={q2}
            />
            <div className="flex-col">
              <label htmlFor="saisieType">type de reponses</label>
              <select
                className="select1"
                required
                onFocus={onChangeFocus}
                name="typeQ2"
                onChange={onHandleInputChange}
                value={typeQ2}
              >
                <option value="text">Texte</option>
                <option value="number">Nombre Entier</option>
              </select>
            </div>
            <TextField
              label="Prix a affecter"
              onFocus={onChangeFocus}
              disabled={typeQ2 === "text" ? true : false}
              type="text"
              name="priceQ2"
              onChange={onHandleInputChange}
              value={priceQ2}
            />
            <TextField
              label="saisie maxi autorisee"
              onFocus={onChangeFocus}
              disabled={typeQ2 === "text" ? true : false}
              type="text"
              name="maxiQ2"
              onChange={onHandleInputChange}
              value={maxiQ2}
            />
          </div>
        </div>
        <div className="section-container2 flex-col box1">
          <Button
            color="success"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>Question 3</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card2 flex-col">
            <TextField
              label="Question"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="q3"
              onChange={onHandleInputChange}
              value={q3}
            />
            <div className="flex-col">
              <label htmlFor="saisieType">type de reponses</label>
              <select
                className="select1"
                required
                onFocus={onChangeFocus}
                name="typeQ3"
                onChange={onHandleInputChange}
                value={typeQ3}
              >
                <option value="text">Texte</option>
                <option value="number">Nombre Entier</option>
              </select>
            </div>
            <TextField
              label="Prix a affecter"
              onFocus={onChangeFocus}
              disabled={typeQ3 === "text" ? true : false}
              type="text"
              name="priceQ3"
              onChange={onHandleInputChange}
              value={priceQ3}
            />
            <TextField
              label="saisie maxi autorisee"
              onFocus={onChangeFocus}
              disabled={typeQ3 === "text" ? true : false}
              type="text"
              name="maxiQ3"
              onChange={onHandleInputChange}
              value={maxiQ3}
            />
          </div>
        </div>
        <div className="section-container2 flex-col box1">
          <Button
            color="success"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>Question 4</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card2 flex-col">
            <TextField
              label="Question"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="q4"
              onChange={onHandleInputChange}
              value={q4}
            />
            <div className="flex-col">
              <label htmlFor="saisieType">type de reponses</label>
              <select
                className="select1"
                required
                onFocus={onChangeFocus}
                name="typeQ4"
                onChange={onHandleInputChange}
                value={typeQ4}
              >
                <option value="text">Texte</option>
                <option value="number">Nombre Entier</option>
              </select>
            </div>
            <TextField
              label="Prix a affecter"
              onFocus={onChangeFocus}
              disabled={typeQ4 === "text" ? true : false}
              type="text"
              name="priceQ4"
              onChange={onHandleInputChange}
              value={priceQ4}
            />
            <TextField
              label="saisie maxi autorisee"
              onFocus={onChangeFocus}
              disabled={typeQ4 === "text" ? true : false}
              type="text"
              name="maxiQ4"
              onChange={onHandleInputChange}
              value={maxiQ4}
            />
          </div>
        </div>
      </div>
      <div className="section-container3 ">
        <Button
          color="success"
          className="clicked choose-box animation-on-hover btn1"
        >
          <h5>precisions</h5>
        </Button>
        <hr />
        <div className="inputs-card3 ">
          <div className="titleEv">
            <TextField
              fullWidth
              label="montant max affectable"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="maxAffect"
              onChange={onHandleInputChange}
              value={maxAffect}
            />
          </div>
          <div className="DetailEv">
            <TextField
              fullWidth
              label="lien paybox"
              onFocus={onChangeFocus}
              multiline
              rows={3}
              type="text"
              name="linkPay"
              onChange={onHandleInputChange}
              value={linkPay}
            />
          </div>
        </div>
      </div>
      <div className="btn-modal-container">
        <Button
          className="btn-simple animation-on-hover close-modal-btn"
          color="danger"
          onClick={closeModal}
        >
          X
        </Button>
        <Button
          className="save-btn btn-simple animation-on-hover"
          disabled={!bolSave}
          color="info"
          variant="contained"
          onClick={onFormSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
