import React, { useEffect } from "react";
//import icons from "../assets/variables/icons";
//import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap";

export function DataImgsHeaderUtilisateur({ crmCount }) {
  return (
    <div className="img-header-container flex">
      <Col lg="3" md="6">
        <Card className="card-stats">
          <CardBody className="testCard">
            <Row>
              <Col xs="5">
                <div className="info-icon text-center icon-success">
                  <i className="far fa-user"></i>
                </div>
              </Col>
              <Col xs="7">
                <div className="numbers">
                  <p className="card-category">Nb Utilisateurs</p>
                  <CardTitle tag="h4">{crmCount}</CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-sound-wave" />
            </div>
          </CardFooter>
        </Card>
      </Col>
    </div>
  );
}
