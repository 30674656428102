import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  signup,
  removeUser,
  loadUsers,
} from "../store/actions/userAction";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import icons from "../assets/variables/icons";

export function Login() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  /*login cred*/
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const onLoginHandleChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "log-mail") setLoginEmail(value);
    if (name === "log-pass") setLoginPassword(value);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const doLogin = async (ev) => {
    ev.preventDefault();
    if (!loginEmail || !loginPassword) {
      alert("Please enter email/password");
      //window.location.assign("/#/login");
    } else {
      const userCred = {
        email: loginEmail,
        password: loginPassword,
      };
      //console.log(userCred);
      await dispatch(login(userCred));
      // login(userCred);

      console.log("loggedinuser");
      window.location.assign("/#/histodons");

      // window.location.reload(true);
    }
  };

  return (
    <div className="inputs-container flex card-primary login-card-container">
      {/*LOG IN FORM*/}
      <Card style={{ width: "40rem" }} className="login-card">
        <CardImg
          top
          src={require("../assets/img/card-success.png").default}
          alt="..."
        />
        <CardBody className="card-body">
          <h2>Connexion</h2>

          <div className="flex-col log-inputs-container">
            <input
              type="text"
              name="log-mail"
              className="loginInput form-control"
              value={loginEmail}
              onChange={onLoginHandleChange}
              placeholder="Email"
            />
            <input
              type={showPassword ? "text" : "password"}
              className="loginInput pass-login-input form-control"
              name="log-pass"
              value={loginPassword}
              onChange={onLoginHandleChange}
              onDoubleClick={handleClickShowPassword}
              placeholder="Password"
            />
          </div>
          <div className="flex-col login-btn-container">
            <Button
              color="success"
              className="animation-on-hover"
              onClick={doLogin}
            >
              Se Connecter
            </Button>
            <Link className="signup-link" to="/signup">
              <Button className="btn-link" color="danger">
                Pas encore inscrit ? inscrivez-vous...
              </Button>
            </Link>
            <Link className="signup-link" to="/loginmdp">
              <Button className="btn-link" color="danger">
                mot de passe oublié ?
              </Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
