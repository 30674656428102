import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { crmService } from "../services/crmService";
import { confirmAlert } from "react-confirm-alert";
import {
  loadCrms,
  removeCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import { Modal2 } from "./Modal2";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { loadUsers, saveUser } from "../store/actions/userAction";

export function HistoDonsgl() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const users = useSelector((state) => state.userReducer.users);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  // const crmsSoldDeb = useSelector((state) => state.crmReducer.crmsSoldDeb);
  // const crmsSoldCred = useSelector((state) => state.crmReducer.crmsSoldCred);
  // const crmUSoldDeb = useSelector((state) => state.crmReducer.crmUSoldDeb);
  // const crmUSoldCred = useSelector((state) => state.crmReducer.crmUSoldCred);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const filterCrm = useSelector((state) => state.crmReducer.filterCrm);
  const [openFlag, setOpenFlag] = useState(false);
  const [crmId, setCrmId] = useState("");

  let soldeTotalDebit2 = "";
  let soldeTotalCredit2 = "";

  useEffect(() => {
    setCrmId("");
    dispatch(setEditCrm(0));
    dispatch(setEditCrmuSoldDeb(0));
    dispatch(setEditCrmuSoldCred(0));
    eventBus.on("editing", () => {
      setOpenFlag(false);
    });
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    if (loggedInUser.isManager) {
      dispatch(loadUsers());
    }
    dispatch(loadCrms(filterCrm));
    // console.log("toto");
  }, [openFlag]);
  function Tridata() {
    //const crms2 = crms.saisie.slice();
    crms.sort((a, b) => {
      const first = b.dateMaj;
      const second = a.dateMaj;
      return first > second ? 1 : first < second ? -1 : 0;
    });
  }

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  const onDelete = (rowId) => {
    // console.log(rowId);
    confirmAlert({
      title: "confirmation de la suppression",
      message: "Cette action est irreversible, confirmez-vous la suppression ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            dispatch(removeCrm(rowId));
            users.map((user) => {
              user.crmId.map((el, index) => {
                if (el === rowId) {
                  user.crmId.splice(index, 1);
                  dispatch(saveUser(user));
                }
              });
            });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  };
  const onEdit = async (rowId) => {
    if (crmIdxEdited == rowId) {
      alert("Someone else is editing this row, please try later");
      return;
    } else {
      //console.log('redux state idx',crmIdxEdited);
      dispatch(setEditCrm(rowId));
      setCrmId(rowId);
      setOpenFlag(true);

      // }
    }
  };

  const onEdit2 = async (crm) => {
    dispatch(setEditCrm(crm._id));
    //  setCrmId(crm._id);
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    calcSoldCred(crm);
    calcSoldDeb(crm);
    dispatch(setEditCrmuSoldDeb(soldeTotalDebit2));
    dispatch(setEditCrmuSoldCred(soldeTotalCredit2));
    window.location.assign("/#/histodoncrm");
  };

  function calcDeb(crm) {
    let res = "";
    crm.saisie.forEach((el) => {
      res = (Number(res) + Number(el.debit)).toFixed(2).toString();
    });
    return res;
  }
  function calcCred(crm) {
    let res = "";
    crm.saisie.forEach((el) => {
      res = (Number(res) + Number(el.credit)).toFixed(2).toString();
    });
    return res;
  }
  function calcSoldCred(crm) {
    let res = "";
    let resd = "";
    let resc = "";
    crm.saisie.forEach((el) => {
      resd = (Number(resd) + Number(el.debit)).toString();
    });
    crm.saisie.forEach((el) => {
      resc = (Number(resc) + Number(el.credit)).toString();
    });
    if (Number(resc) > Number(resd)) {
      res = (Number(resc) - Number(resd)).toFixed(2).toString();
    } else {
      res = "0";
    }

    soldeTotalCredit2 = (Number(soldeTotalCredit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldCred(soldeTotalCredit2));
    return res;
  }
  function calcSoldDeb(crm) {
    let res = "";
    let resd = "";
    let resc = "";
    crm.saisie.forEach((el) => {
      resd = (Number(resd) + Number(el.debit)).toString();
    });
    crm.saisie.forEach((el) => {
      resc = (Number(resc) + Number(el.credit)).toString();
    });
    if (Number(resc) < Number(resd)) {
      res = (Number(resd) - Number(resc)).toFixed(2).toString();
    } else {
      res = "0";
    }
    soldeTotalDebit2 = (Number(soldeTotalDebit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldDeb(soldeTotalDebit2));
    return res;
  }

  const onAdd = () => {
    setOpenFlag(true);
  };

  if (!loggedInUser || !loggedInUser.isApprouved)
    return (
      <div>
        <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
        <h3>
          {" "}
          vous n'avez pas encore été approuvé par le responsable de
          l'application, patience ...{" "}
        </h3>
      </div>
    );
  return (
    <div>
      {openFlag && <Modal2 crmId={crmId} />}

      <DashboardHeader crmCount={crms.length} onAdd={onAdd} />

      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>Nom </th>
            <th>prenom </th>
            <th>Evennement </th>
            <th>date</th>

            <th> Debit (nis) </th>
            <th> Credit (nis) </th>

            <th>collecteur </th>
            <th>mode </th>
          </tr>
        </thead>
        <TableBody className="table-body">
          {Tridata()}
          {crms.map((crm) =>
            crm.saisie.map((don) => (
              <StyledTableRow key={crm._id}>
                <StyledTableCell className="partner">
                  {crm.lastName}
                </StyledTableCell>

                <StyledTableCell className="partner">
                  {crm.firstName}
                </StyledTableCell>
                <StyledTableCell className="partner">
                  {don.event}
                </StyledTableCell>
                <StyledTableCell className="partner">
                  {moment(don.dateEvent).format("DD-MM-YY")}
                </StyledTableCell>

                <StyledTableCell className="partner">
                  {don.debit}
                </StyledTableCell>

                <StyledTableCell className="partner">
                  {don.credit}
                </StyledTableCell>
                <StyledTableCell className="partner">
                  {don.destinataire}
                </StyledTableCell>

                <StyledTableCell className="partner">
                  {don.mode}
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
}
