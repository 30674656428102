import React, { useState, useEffect } from "react";
import { eventBus } from "../services/eventBusService";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../services/userService";
import { saveCrm, setEditCrm } from "../store/actions/crmAction";
import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { saveUser } from "../store/actions/userAction";

export function LineForm5({ closeModal, data3, keyData }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userReducer.users);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  const userIdxEdited = useSelector((state) => state.userReducer.userIdxEdited);
  const [costumerFirstName, setCostumerFirstName] = useState("");
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerTelephone, setcostumerTelephone] = useState("");
  const [costumerSaisie, setcostumerSaisie] = useState([]);
  const [costumerDateMaj, setcostumerDateMaj] = useState("");
  const [costumerId, setcostumerId] = useState("");
  const [listeCaissiers, setListeCaissiers] = useState([]);
  let listeCaissiers2 = [];

  const [saisieEvent, setSaisieEvent] = useState("");
  const [saisieDebit, setSaisieDebit] = useState("");
  const [saisieCredit, setSaisieCredit] = useState("");
  const [saisieMode, setSaisieMode] = useState("");
  const [saisieDestinataireId, setSaisieDestinataireId] = useState("");
  const [saisieDestinataire, setSaisieDestinataire] = useState("");
  const [saisieDateEvent, setSaisieDateEvent] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [saisieType, setSaisieType] = useState("debit");
  const [saisieSomme, setSaisieSomme] = useState("0");

  const [chosenBox1, setChosenBox1] = useState(0);
  const [chosenBox2, setChosenBox2] = useState(0);
  const [chosenBox3, setChosenBox3] = useState(0);
  const [chosenBox4, setChosenBox4] = useState(0);

  const [colorFlag1, setColorFlag1] = useState(false);
  const [colorFlag2, setColorFlag2] = useState(false);
  const [colorFlag3, setColorFlag3] = useState(false);
  const [colorFlag4, setColorFlag4] = useState(false);
  const [focusIdx, setFocusIdx] = useState(0);

  useEffect(() => {
    if (userIdxEdited) getDetailesByuserId();
    // console.log("crmid1", crmId);
  }, []);

  const getDetailesByuserId = async () => {
    const data = await userService.getById(userIdxEdited);
    // console.log(data);

    setCostumerFirstName(data.firstName);
    setCostumerLastName(data.lastName);
    setcostumerTelephone(data.telephone);
    setcostumerSaisie(data.decaissement);
    setcostumerDateMaj(data.dateMaj);
    setcostumerId(data._id);
    //  console.log("data3", data3);
    // console.log("keyData", keyData);

    if (keyData >= 0) {
      setSaisieEvent(data3[keyData].event);
      setSaisieDebit(data3[keyData].debit);
      setSaisieCredit(data3[keyData].credit);
      setSaisieMode(data3[keyData].mode);
      setSaisieDestinataireId(data3[keyData].destinataire_id);
      setSaisieDestinataire(data3[keyData].destinataire);
      setSaisieDateEvent(moment(data3[keyData].dateEvent).format("YYYY-MM-DD"));
      if (data3[keyData].credit === "0") {
        setSaisieSomme(data3[keyData].debit);
        setSaisieType("debit");
      }

      if (data3[keyData].debit === "0") {
        setSaisieSomme(data3[keyData].credit);
        setSaisieType("credit");
      }

      // console.log("saisieDebit", saisieDebit);
      // console.log("saisieCredit", saisieCredit);
      //console.log("saisieSomme", saisieSomme);
    }
    if (loggedInUser.isManager) {
      users.map((user) => {
        if (
          user.isManager &&
          !user.isAdmin &&
          !(user._id === loggedInUser._id)
        ) {
          listeCaissiers2.push({
            _id: user._id,
            name: user.firstName + " " + user.lastName,
          });
        }
        if (user.isAdmin) {
          if (user.fournisseurs) {
            user.fournisseurs.map((el) => {
              listeCaissiers2.push({
                _id: el._id,
                name: el.firstName + " " + el.lastName,
              });
            });
          }
        }
      });
      //console.log("caissier", listeCaissiers);
      setListeCaissiers(listeCaissiers2);
    }
  };

  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "saisieType":
        {
          setSaisieType(value);
          setSaisieSomme("0");
        }

        break;
      case "event":
        setSaisieEvent(value);
        break;
      case "somme":
        {
          setSaisieSomme(value);
          if (saisieType === "debit") {
            setSaisieDebit(value);
            setSaisieCredit("0");
          }
          if (saisieType === "credit") {
            setSaisieCredit(value);
            setSaisieDebit("0");
          }
        }
        break;
      case "saisieDestinataire":
        {
          setSaisieDestinataire(value);
          let vallA = "";
          listeCaissiers.map((el) => {
            if (el.name === value) {
              vallA = el._id;
            }
          });
          // console.log(vallA);
          setSaisieDestinataireId(vallA);
        }
        break;
      case "saisieMode":
        setSaisieMode(value);
        break;
      case "dateEvent":
        setSaisieDateEvent(value);
        // console.log("date", moment(saisieDateEvent).format());
        break;

      default:
        break;
    }
  };
  const beforeOnFormSubmit = () => {
    onFormSubmit();
  };
  const onFormSubmit = () => {
    let bol = false;
    if (saisieEvent !== "" && saisieSomme !== "0") {
      if (1 === 1) {
        if (saisieMode !== "" && saisieDestinataire !== "") {
          bol = true;
        }
      } else {
        bol = true;
      }
    }

    if (bol) {
      const newCrmsaisie = {
        debit: saisieDebit,
        event: saisieEvent,
        dateEvent: moment(saisieDateEvent).format(),
        credit: saisieCredit,
        mode: saisieMode,
        destinataire_id: saisieDestinataireId,
        destinataire: saisieDestinataire,
      };

      if (keyData >= 0) {
        data3.splice(keyData, 1, newCrmsaisie);
      } else {
        data3.push(newCrmsaisie);
      }
      users.map((user) => {
        if (user._id === userIdxEdited) {
          user.decaissement = data3;
          dispatch(saveUser(user));
        }
      });

      // dispatch(setEditCrm(0));
      eventBus.emit("editing");
    } else alert("tous les champ sont obligatoires...");
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;
    //console.log(ev);

    setFocusIdx(1);
  };

  if (!loggedInUser) return <div>loading</div>;

  return (
    <div className="line-big-container">
      <div className="line-form-container flex">
        <div className="section-container flex-col box1">
          {/* <Button
            color="info"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>saisie</h5>
         </Button>
          <hr className="focus-line" />*/}
          <div className="inputs-card flex-col">
            <TextField
              label="detail de la Saisie"
              required
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="event"
              onChange={onHandleInputChange}
              value={saisieEvent}
            />
            <div className="flex-col">
              <label htmlFor="saisieType">type de saisie</label>
              <select
                className="select1"
                required
                onFocus={onChangeFocus}
                name="saisieType"
                onChange={onHandleInputChange}
                value={saisieType}
              >
                <option value="debit">Debit (paiement)</option>
                <option value="credit">Credit (Encaissement)</option>
              </select>
            </div>

            <div>
              <div className="flex-col">
                <label htmlFor="saisieDestinataire">
                  Destinataire / Origine
                </label>
                <select
                  className="select1"
                  onFocus={onChangeFocus}
                  name="saisieDestinataire"
                  onChange={onHandleInputChange}
                  value={saisieDestinataire}
                >
                  <option value="">Chosir...</option>
                  {listeCaissiers.map((usercaissier) => (
                    <option key={usercaissier._id} value={usercaissier.name}>
                      {usercaissier.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-col">
                <label htmlFor="saisieMode">Mode de Paiement</label>
                <select
                  className="select1"
                  onFocus={onChangeFocus}
                  name="saisieMode"
                  onChange={onHandleInputChange}
                  value={saisieMode}
                >
                  <option value="">Chosir...</option>
                  <option value="Especes">Especes</option>
                  <option value="Cheque">Cheques</option>
                  <option value="Virement">Virement</option>
                  <option value="Autre (PayBox, Bit...)">
                    Autre(paybox, bit....)
                  </option>
                </select>
              </div>
            </div>

            <TextField
              label="somme"
              required
              onFocus={onChangeFocus}
              type="number"
              name="somme"
              onChange={onHandleInputChange}
              value={saisieSomme}
            />

            <label className="date-label" htmlFor="dateEvent">
              Date
            </label>
            <TextField
              onFocus={onChangeFocus}
              type="date"
              required
              name="dateEvent"
              onChange={onHandleInputChange}
              value={saisieDateEvent}
            />
            <div className="btn-modal-container">
              <Button
                className="btn-simple animation-on-hover close-modal-btn"
                color="danger"
                onClick={closeModal}
              >
                X
              </Button>
              <Button
                className="save-btn btn-simple animation-on-hover"
                color="success"
                variant="contained"
                onClick={beforeOnFormSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
