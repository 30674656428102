import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LineForm3 } from "../cmp/LineForm3";
import { LineForm5 } from "../cmp/LineForm5CollecteurDetail";
import { eventBus } from "../services/eventBusService";
import { loadCrms, removeCrm, setEditCrm } from "../store/actions/crmAction";

export function Modal5({ data3, keyData }) {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);

  const [isShown, setIsShown] = useState(true);

  const closeModal = () => {
    setIsShown(false);
    eventBus.emit("editing");
    eventBus.emit("added");
  };

  return (
    <div>
      <div
        className={`modal-wrapper ${isShown ? "" : "hide"}`}
        onClick={closeModal}
      >
        <div
          className="modal-content modal-container"
          onClick={(ev) => ev.stopPropagation()}
        >
          <LineForm5 closeModal={closeModal} data3={data3} keyData={keyData} />
        </div>
      </div>
    </div>
  );
}
