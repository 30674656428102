import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  sendPassword,
  signup,
  removeUser,
  loadUsers,
} from "../store/actions/userAction";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import icons from "../assets/variables/icons";

export function Loginmdp() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  /*login cred*/
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const onLoginHandleChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "log-mail") setLoginEmail(value);
    if (name === "log-pass") setLoginPassword(value);
  };

  const doSendPassword = async (ev) => {
    ev.preventDefault();
    if (!loginEmail) {
      alert("Please enter email");
      //window.location.assign("/#/login");
    } else {
      const userCred = {
        email: loginEmail,
        // password: loginPassword,
      };
      //console.log(userCred);
      await dispatch(sendPassword(userCred));
      // login(userCred);
      alert("un mail vous a été envoyé à l'adresse mail indiquée");
      // console.log("loggedinuser");
      window.location.assign("/#/login");

      // window.location.reload(true);
    }
  };

  return (
    <div className="inputs-container flex card-primary login-card-container">
      {/*LOG IN FORM*/}
      <Card style={{ width: "40rem" }} className="login-card">
        <CardImg
          top
          src={require("../assets/img/card-success.png").default}
          alt="..."
        />
        <CardBody className="card-body">
          <h2>Connection</h2>

          <div className="flex-col log-inputs-container">
            <input
              type="text"
              name="log-mail"
              className="loginInput form-control"
              value={loginEmail}
              onChange={onLoginHandleChange}
              placeholder="Email"
            />
          </div>
          <div className="flex-col login-btn-container">
            <Button
              color="success"
              className="animation-on-hover"
              onClick={doSendPassword}
            >
              Renvoyer le mot de passe
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
