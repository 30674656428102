import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { crmService } from "../services/crmService";
import { confirmAlert } from "react-confirm-alert";
import {
  loadCrms,
  removeCrm,
  saveCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import {
  Row,
  Col,
  Card,
  Button,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap";
import { Modal2 } from "./Modal2";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Select from "react-select";
import {
  loadUsers,
  saveUser,
  sendEmail,
  setEditUser,
  loadAdmin,
} from "../store/actions/userAction";
import { DashboardHeaderCollecteur } from "../cmp/DashboardHeaderCollecteur";
import { DashboardHeaderEvennements } from "../cmp/DashboardHeaderEvennements";
import { Modal6Fournisseurs } from "./Modal6Fournisseurs";
import { Modal8Evennement } from "./Modal8Evennement";
import { format } from "path";

export function HistoEvennements() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const users = useSelector((state) => state.userReducer.users);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  const userIdxEdited = useSelector((state) => state.userReducer.userIdxEdited);
  // const crmsSoldDeb = useSelector((state) => state.crmReducer.crmsSoldDeb);
  // const crmsSoldCred = useSelector((state) => state.crmReducer.crmsSoldCred);
  // const crmUSoldDeb = useSelector((state) => state.crmReducer.crmUSoldDeb);
  // const crmUSoldCred = useSelector((state) => state.crmReducer.crmUSoldCred);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const filterCrm = useSelector((state) => state.crmReducer.filterCrm);
  const [openFlag, setOpenFlag] = useState(false);
  const [openFlag3, setOpenFlag3] = useState(false);
  const [openFlag4, setOpenFlag4] = useState(false);
  const [crmId, setCrmId] = useState("");
  const [crmId2, setCrmId2] = useState("");
  const [rep1, setRep1] = useState("");
  const [rep2, setRep2] = useState("");
  const [rep3, setRep3] = useState("");
  const [rep4, setRep4] = useState("");
  const [participantId, setParticipantId] = useState(null);
  const [totalParticipant, setTotalParticipant] = useState("");
  const [switched, setswitched] = useState(false);
  const [afficheListe, setAfficheListe] = useState(false);
  const [afficheParticipe, setAfficheParticipe] = useState(false);
  const [bolSave, setBolsave] = useState(true);

  const [userAdmin, setUserAdmin] = useState(null);
  const [ev1, setEv1] = useState(null);
  // const [compteur, setCompteur] = useState(0);
  let compteur = 0;
  //let titleEv = "";
  //let ev1;
  let soldeTotalDebit2 = "";
  let soldeTotalCredit2 = "";

  useEffect(async () => {
    //setCrmId("");
    // dispatch(setEditCrm(0));
    // dispatch(setEditCrmuSoldDeb(0));
    //  dispatch(setEditCrmuSoldCred(0));
    eventBus.on("editing", () => {
      setOpenFlag(false);
    });
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    //console.log("toto");
    await dispatch(loadAdmin());
    await dispatch(loadCrms());
  }, [openFlag, openFlag3, openFlag4]);
  useEffect(() => {
    if (ev1 !== null) {
      checkDisabled();
    }
  }, [rep1, rep2, rep3, rep4]);

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  const onEdit2 = (eve) => {
    // dispatch(setEditUser());
    setCrmId2(eve._id);
    if (eve.status === "Actif") {
      setswitched(true);
    } else setswitched(false);
    // console.log("user._id", eve.titleEv);
    setEv1(eve);
    setOpenFlag4(true);
    //  setCrmId(crm._id);
    //  soldeTotalDebit2 = "0";
    //  soldeTotalCredit2 = "0";
    //  calcSoldCred(user);
    //  calcSoldDeb(user);
    //  dispatch(setEditCrmuSoldDeb(soldeTotalDebit2));
    //  dispatch(setEditCrmuSoldCred(soldeTotalCredit2));
    // window.location.assign("/#/gestionfournisseurdetail");
    // console.log(userIdxEdited, user._id);
  };

  function calcDeb(user) {
    let res = "0";
    let b = 0;
    users.map((user2) => {
      if (user2.isManager) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === user._id) {
            if (isNaN(Number(el.debit))) {
              b = 0;
            } else {
              b = Number(el.debit);
            }
            res = (Number(res) + b).toFixed(2).toString();
          }
        });
      }
    });

    return res;
  }
  function calcCred(user) {
    let res = "0";
    let b = 0;

    users.map((user2) => {
      if (user2.isManager) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === user._id) {
            if (isNaN(Number(el.credit))) {
              b = 0;
            } else {
              b = Number(el.credit);
            }
            res = (Number(res) + b).toFixed(2).toString();
          }
        });
      }
    });

    return res;
  }
  function calcSoldCred(user) {
    let res = "0";
    let resc = calcCred(user);
    let resd = calcDeb(user);
    if (Number(resc) > Number(resd)) {
      res = (Number(resc) - Number(resd)).toFixed(2).toString();
    } else {
      res = "0";
    }

    soldeTotalCredit2 = (Number(soldeTotalCredit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldCred(soldeTotalCredit2));
    return res;
  }
  function calcSoldDeb(user) {
    let res = "";
    let resd = calcDeb(user);
    let resc = calcCred(user);

    if (Number(resc) < Number(resd)) {
      res = (Number(resd) - Number(resc)).toFixed(2).toString();
    } else {
      res = "0";
    }
    soldeTotalDebit2 = (Number(soldeTotalDebit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldDeb(soldeTotalDebit2));
    return res;
  }
  const onEdit = async () => {
    /*if (crmIdxEdited == rowId) {
      alert("Someone else is editing this row, please try later");
      return;
    } else {*/
    //console.log('redux state idx',crmIdxEdited);
    // dispatch(setEditCrm(rowId));
    setCrmId(crmId2);
    setOpenFlag4(false);
    setOpenFlag(true);
    // }
  };
  const onAdd = () => {
    setCrmId("");
    setOpenFlag(true);
  };
  const onListAffiche = () => {
    if (afficheListe) {
      setAfficheListe(false);
      setAfficheParticipe(false);
    } else {
      checkTot();
      setOpenFlag3(toggleV(openFlag3));
      setAfficheParticipe(false);
      setAfficheListe(true);
    }
  };
  const checkTot = async () => {
    if (!ev1.statusDeverse) {
      users.map(async (user) => {
        if (user.isAdmin) {
          user.evennements.map((ev) => {
            if (ev._id === crmId2) {
              ev.responses[0]["rep1"] = "0";
              ev.responses[0]["rep2"] = "0";
              ev.responses[0]["rep3"] = "0";
              ev.responses[0]["rep4"] = "0";
              ev.responses[0]["totalRep"] = "0";
              ev.responses.map((el) => {
                let totalRep = "";
                if (ev1.typeQ1 === "number" && ev1.priceQ1 !== "") {
                  totalRep = (
                    Number(totalRep) +
                    Number(ev1.priceQ1) * Number(el.rep1)
                  ).toString();
                }
                if (ev1.typeQ2 === "number" && ev1.priceQ2 !== "") {
                  totalRep = (
                    Number(totalRep) +
                    Number(ev1.priceQ2) * Number(el.rep2)
                  ).toString();
                }
                if (ev1.typeQ3 === "number" && ev1.priceQ3 !== "") {
                  totalRep = (
                    Number(totalRep) +
                    Number(ev1.priceQ3) * Number(el.rep3)
                  ).toString();
                }
                if (ev1.typeQ4 === "number" && ev1.priceQ4 !== "") {
                  totalRep = (
                    Number(totalRep) +
                    Number(ev1.priceQ4) * Number(el.rep4)
                  ).toString();
                }
                console.log("totalRep", totalRep);
                console.log(
                  "max affect",
                  ev1.maxAffect,
                  ev1.typeQ1,
                  ev1.priceQ1
                );

                if (ev1.maxAffect !== "") {
                  if (Number(totalRep) > Number(ev1.maxAffect)) {
                    totalRep = ev1.maxAffect;
                  }
                }
                el.totalRep = totalRep;

                if (ev1.typeQ1 === "number") {
                  ev.responses[0]["rep1"] = (
                    Number(ev.responses[0]["rep1"]) + Number(el.rep1)
                  ).toString();
                }
                if (ev1.typeQ2 === "number") {
                  ev.responses[0]["rep2"] = (
                    Number(ev.responses[0]["rep2"]) + Number(el.rep2)
                  ).toString();
                }
                if (ev1.typeQ3 === "number") {
                  ev.responses[0]["rep3"] = (
                    Number(ev.responses[0]["rep3"]) + Number(el.rep3)
                  ).toString();
                }
                if (ev1.typeQ3 === "number") {
                  ev.responses[0]["rep4"] = (
                    Number(ev.responses[0]["rep4"]) + Number(el.rep4)
                  ).toString();
                }
                ev.responses[0]["totalRep"] = (
                  Number(ev.responses[0]["totalRep"]) + Number(el.totalRep)
                ).toString();
              });
            }
          });
          await dispatch(saveUser(user));
          await dispatch(loadAdmin());
        }
      });
      users.map(async (user) => {
        if (user.isAdmin) {
          user.evennements.map((ev) => {
            if (ev._id === crmId2) {
              setEv1(ev);
            }
          });
        }
      });
    }
  };

  const onParticipe = () => {
    if (!ev1.statusDeverse) {
      if (afficheParticipe) {
        setAfficheListe(false);
        setAfficheParticipe(false);
      } else {
        setAfficheListe(false);
        setAfficheParticipe(true);
      }
    }
  };

  const saveParticipant = () => {
    let totalRep = "";
    if (ev1.typeQ1 === "number" && ev1.priceQ1 !== "") {
      totalRep = (
        Number(totalRep) +
        Number(ev1.priceQ1) * Number(rep1)
      ).toString();
    }
    if (ev1.typeQ2 === "number" && ev1.priceQ2 !== "") {
      totalRep = (
        Number(totalRep) +
        Number(ev1.priceQ2) * Number(rep2)
      ).toString();
    }
    if (ev1.typeQ3 === "number" && ev1.priceQ3 !== "") {
      totalRep = (
        Number(totalRep) +
        Number(ev1.priceQ3) * Number(rep3)
      ).toString();
    }
    if (ev1.typeQ4 === "number" && ev1.priceQ4 !== "") {
      totalRep = (
        Number(totalRep) +
        Number(ev1.priceQ4) * Number(rep4)
      ).toString();
    }
    console.log("totalRep", totalRep);
    if (ev1.maxAffect !== "") {
      if (Number(totalRep) > Number(ev1.maxAffect)) {
        totalRep = ev1.maxAffect;
      }
    }
    let saveOk = true;
    users.map((user) => {
      if (user.isAdmin) {
        user.evennements.map((ev) => {
          if (ev._id === crmId2) {
            ev.responses.map((el) => {
              if (el.repId === participantId.value) {
                saveOk = false;
              }
            });
          }
        });
      }
    });
    if (saveOk) {
      let ResponsesNew = [];
      users.map((user) => {
        if (user.isAdmin) {
          user.evennements.map((ev) => {
            if (ev._id === crmId2) {
              ResponsesNew = ev.responses;
              ResponsesNew.push({
                rep1: rep1,
                rep2: rep2,
                rep3: rep3,
                rep4: rep4,
                repId: participantId.value,
                nameId: participantId.label,
                totalRep: totalRep,
                dateRep: moment().format("YYYY-MM-DD"),
              });

              ev.responses = ResponsesNew;
              dispatch(saveUser(user));
            }
          });
        }
      });
    }

    if (!saveOk) {
      confirmAlert({
        title: "participant deja inscrit",
        message:
          "le participant " +
          participantId.label +
          " est deja inscrit, vous ne pouvez plus modifier, merci de contacter l'administrateur le cas echeant...",
        buttons: [
          {
            label: "ok",
            onClick: () => {},
          },
        ],
      });
    } else {
      if (!loggedInUser.isManager) {
        sendEmail({
          to: loggedInUser.email,
          subject: "inscription a l'evennement : " + ev1.titleEv,
          /* text:
          "bonjour " +
          loggedInUser.firstName +
          " " +
          loggedInUser.lastName +
          " votre inscription a l'evennement  " +
          ev1.titleEv +
          " le " +
          moment(ev1.dateEv).format("DD/MM/YYYY") +
          " concernant le participant : " +
          participantId.label +
          " a bien été enregistrée. Le cout total de votre participation est de : " +
          totalRep +
          " shekels , merci",*/

          html: `<p>bonjour <strong>${loggedInUser.firstName} ${
            loggedInUser.lastName
          },</strong><br><br> Votre inscription a l'evennement ${
            ev1.titleEv
          } le ${moment(ev1.dateEv).format(
            "DD/MM/YYYY"
          )}  concernant le participant : ${
            participantId.label
          }  a bien été enregistrée.<br><br>Le cout total de votre participation est de : <strong>${totalRep} shekel</strong> <br><br>Vous pouvez payer votre participation en cliquant sur le lien suivant : ${
            ev1.linkPay
          } 
          <br>${ev1.q1} : <strong>${rep1}</strong>
          <br>${ev1.q2} : <strong>${rep2}</strong>
          <br>${ev1.q3} : <strong>${rep3}</strong>
          <br>${ev1.q4} : <strong>${rep4}</strong>
          <br><br>L'equipe de bet yossef vous remercie</p>`,
        });
      }
      sendEmail({
        to: "betyossefhaim.gs@gmail.com",
        subject:
          participantId.label + "est inscrit a l'evennement : " + ev1.titleEv,
        /* text:
          "bonjour " +
          loggedInUser.firstName +
          " " +
          loggedInUser.lastName +
          " votre inscription a l'evennement  " +
          ev1.titleEv +
          " le " +
          moment(ev1.dateEv).format("DD/MM/YYYY") +
          " concernant le participant : " +
          participantId.label +
          " a bien été enregistrée. Le cout total de votre participation est de : " +
          totalRep +
          " shekels , merci",*/

        html: `<p>bonjour <strong>${loggedInUser.firstName} ${
          loggedInUser.lastName
        },</strong><br><br> Votre inscription a l'evennement ${
          ev1.titleEv
        } le ${moment(ev1.dateEv).format(
          "DD/MM/YYYY"
        )}  concernant le participant : ${
          participantId.label
        }  a bien été enregistrée.<br><br>Le cout total de votre participation est de : <strong>${totalRep} shekel</strong> <br><br>Vous pouvez payer votre participation en cliquant sur le lien suivant : ${
          ev1.linkPay
        } 
        <br><br>${ev1.q1} : <strong>${rep1}</strong>
          <br>${ev1.q2} : <strong>${rep2}</strong>
          <br>${ev1.q3} : <strong>${rep3}</strong>
          <br>${ev1.q4} : <strong>${rep4}</strong>
          <br><br>L'equipe de bet yossef vous remercie</p>`,
      });

      confirmAlert({
        title: "confirmation d'inscription",
        message: "le participant " + participantId.label + " est bien inscrit",
        buttons: [
          {
            label: "ok",
            onClick: () => {
              setRep1("");
              setRep2("");
              setRep3("");
              setRep4("");
              setParticipantId(null);
              setBolsave(true);
            },
          },
        ],
      });
    }
    checkTot();
  };
  const calcNbPart = (ev) => {
    if (loggedInUser.isManager) {
      let rep = 0;
      try {
        rep = ev.responses.length - 1;
      } catch (err) {
        rep = 0;
      }
      return rep;
    } else {
      return null;
    }
  };
  const deverse = () => {
    crms.map((crm) => {
      ev1.responses.map((el) => {
        if (el.repId === crm._id) {
          let saisieNew = crm.saisie;
          saisieNew.push({
            debit: el.totalRep !== "" ? el.totalRep : "0",
            event: "participation " + ev1.titleEv,
            dateEvent: moment(ev1.dateEv).format(),
            credit: "0",
            mode: "",
            destinataire_id: "",
            destinataire: "",
          });

          crm.saisie = saisieNew;
          crm.dateMaj = moment().format();
          dispatch(saveCrm(crm));
        }
      });
    });
    dispatch(loadCrms());
  };

  const onDeverse = () => {
    if (!ev1.statusDeverse) {
      confirmAlert({
        title: "confirmation du deversement en compta ?",
        message:
          "cette action est irreversible, vous ne pourrez plus activer et modifier l'evennement, confirnez-vous ? ",
        buttons: [
          {
            label: "Oui",
            onClick: () => {
              setswitched(false);
              users.map((user) => {
                if (user.isAdmin) {
                  user.evennements.map((el, index) => {
                    if (el._id === crmId2) {
                      el.status = "Inactif";
                      el.statusDeverse = true;
                      setEv1(el);
                      dispatch(saveUser(user));
                    }
                  });
                }
              });
              retour();
              deverse();
            },
          },
          {
            label: "Non",
            onClick: () => {},
          },
        ],
      });
    } else {
      confirmAlert({
        title: "confirmation la reinitialisation ?",
        message:
          "cette action est irreversible, supprimera toute les donness des participants a l'evennement, confirnez-vous ? ",
        buttons: [
          {
            label: "Oui",
            onClick: () => {
              users.map((user) => {
                if (user.isAdmin) {
                  user.evennements.map((el, index) => {
                    if (el._id === crmId2) {
                      //el.status = "Inactif";
                      el.responses = [
                        {
                          rep1: "",
                          rep2: "",
                          rep3: "",
                          rep4: "",
                          repId: "",
                          nameId: "TOTAL GENERAL",
                          totalRep: "",
                          dateRep: "",
                        },
                      ];
                      el.statusDeverse = false;
                      setEv1(el);
                      dispatch(saveUser(user));
                    }
                  });
                }
              });
              retour();
            },
          },
          {
            label: "Non",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const onHandleSwitchChange = () => {
    if (switched === true) {
      setswitched(false);
      users.map((user) => {
        if (user.isAdmin) {
          user.evennements.map((el, index) => {
            if (el._id === crmId2) {
              el.status = "Inactif";
              dispatch(saveUser(user));
            }
          });
        }
      });
    } else {
      setswitched(true);
      users.map((user) => {
        if (user.isAdmin) {
          user.evennements.map((el, index) => {
            if (el._id === crmId2) {
              el.status = "Actif";
              dispatch(saveUser(user));
            }
          });
        }
      });
    }
  };
  const retour = () => {
    setCrmId2("");
    setCrmId("");
    setAfficheListe(false);
    setAfficheParticipe(false);
    setRep1("");
    setRep2("");
    setRep3("");
    setRep4("");
    setParticipantId(null);
    setBolsave(true);
    setOpenFlag4(false);
  };
  const initCompteur = () => {
    compteur = 0;
    users.map((user) => {
      if (user.isAdmin) {
        user.evennements.map((ev) => {
          if (ev.status === "Actif") {
            compteur = compteur + 1;
          }
        });
      }
    });
  };
  const onDelete = () => {
    confirmAlert({
      title: "confirmation de la suppression",
      message: "Cette action est irreversible, confirmez-vous la suppression ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            // data3.splice(rowKey, 1);

            users.map((user) => {
              if (user.isAdmin) {
                user.evennements.map((el, index) => {
                  if (el._id === crmId2) {
                    user.evennements.splice(index, 1);
                    dispatch(saveUser(user));
                    setCrmId2("");
                    setOpenFlag4(false);
                    setOpenFlag3(toggleV(openFlag3));
                    // window.location.reload();
                  }
                });
              }
            });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  };
  const onDelete2 = (ev) => {
    if (!ev1.statusDeverse) {
      confirmAlert({
        title: "confirmation de la suppression",
        message:
          "Cette action est irreversible, confirmez-vous la suppression ?",
        buttons: [
          {
            label: "Oui",
            onClick: async () => {
              // data3.splice(rowKey, 1);

              users.map((user) => {
                if (user.isAdmin) {
                  user.evennements.map((el) => {
                    if (el._id === crmId2) {
                      el.responses.map((evev, index) => {
                        if (evev.repId === ev.repId) {
                          el.responses.splice(index, 1);
                          dispatch(saveUser(user));

                          setOpenFlag3(toggleV(openFlag3));
                          // window.location.reload();
                        }
                      });
                    }
                  });
                }
              });
              checkTot();
              // setOpenFlag3(toggleV(openFlag3));
              //await dispatch(loadAdmin());
              // retour();
            },
          },
          {
            label: "Non",
            onClick: () => {},
          },
        ],
      });

      setOpenFlag3(toggleV(openFlag3));
    }
  };
  const toggleV = (x) => {
    return !x;
  };
  const checkDisabled = () => {
    setBolsave(
      !(
        participantId !== null &&
        ((ev1.q1 !== "" && rep1 !== "") || ev1.q1 === "") &&
        ((ev1.q2 !== "" && rep2 !== "") || ev1.q2 === "") &&
        ((ev1.q3 !== "" && rep3 !== "") || ev1.q3 === "") &&
        ((ev1.q4 !== "" && rep4 !== "") || ev1.q4 === "")
      )
    );
  };
  if (!loggedInUser || !loggedInUser.isApprouved)
    return (
      <div>
        <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
        <h3>
          {" "}
          vous n'avez pas encore été approuvé par le responsable de
          l'application, patience ...{" "}
        </h3>
      </div>
    );
  if (openFlag4) {
    // console.log("titleEv", ev1.titleEv);
    return (
      <div>
        <DashboardHeaderEvennements
          onAdd={onAdd}
          crmCount={compteur}
          crmId2={crmId2}
          retour={retour}
          titleEv={ev1.titleEv}
          linkPay={ev1.linkPay}
          detailEv={ev1.detailEv}
          afficheList={afficheListe}
          dateEv={ev1.dateEv}
          onDeverse={onDeverse}
          statusDeverse={ev1.statusDeverse}
          onEdit={onEdit}
          onDelete={onDelete}
          switched={switched}
          onlist={onListAffiche}
          onParticipe={onParticipe}
          handleChangeSwitch={onHandleSwitchChange}
        />
        {afficheParticipe && (
          <Button
            className="save-btn btn-simple animation-on-hover"
            disabled={bolSave}
            color="info"
            variant="contained"
            onClick={saveParticipant}
          >
            Sauvegarder
          </Button>
        )}
        {afficheParticipe && (
          <div className="question-container">
            <div className="question">
              <label>la participation concerne :</label>
            </div>
            <Select
              className="select1"
              placeholder="Selectionner..."
              required
              name="participant"
              onChange={(value) => {
                setParticipantId(value);
                checkDisabled();
              }}
              options={crms.map(
                (crm) =>
                  new Object({
                    value: crm._id,
                    label: crm.firstName.concat(" ", crm.lastName),
                  })
              )}
              value={participantId}
            ></Select>
          </div>
        )}

        {(afficheListe || afficheParticipe) && ev1.q1 !== "" && (
          <div className="question-container">
            <div className="question">
              <label>{ev1.q1}</label>
            </div>
            <div className="response" hidden={afficheListe}>
              <TextField
                fullWidth
                label="votre Reponse"
                type={ev1.typeQ1}
                name="rep1"
                onChange={(value) => {
                  if (
                    Number(value.target.value) <= Number(ev1.maxiQ1) ||
                    ev1.maxiQ1 === "" ||
                    ev1.typeQ1 === "text"
                  ) {
                    setRep1(value.target.value);
                  } else {
                    alert(
                      "merci de saisir une valeur inferieure ou egale a " +
                        ev1.maxiQ1
                    );
                  }
                }}
                value={rep1}
              />
            </div>
          </div>
        )}
        {(afficheListe || afficheParticipe) && ev1.q2 !== "" && (
          <div className="question-container">
            <div className="question">
              <label>{ev1.q2}</label>
            </div>
            <div className="response" hidden={afficheListe}>
              <TextField
                fullWidth
                label="votre Reponse"
                type={ev1.typeQ2}
                name="rep2"
                onChange={(value) => {
                  if (
                    Number(value.target.value) <= Number(ev1.maxiQ2) ||
                    ev1.maxiQ2 === "" ||
                    ev1.typeQ2 === "text"
                  ) {
                    setRep2(value.target.value);
                  } else {
                    alert(
                      "merci de saisir une valeur inferieure ou egale a " +
                        ev1.maxiQ2
                    );
                  }
                }}
                value={rep2}
              />
            </div>
          </div>
        )}
        {(afficheListe || afficheParticipe) && ev1.q3 !== "" && (
          <div className="question-container">
            <div className="question">
              <label>{ev1.q3}</label>
            </div>
            <div className="response" hidden={afficheListe}>
              <TextField
                fullWidth
                label="votre Reponse"
                type={ev1.typeQ3}
                name="rep3"
                onChange={(value) => {
                  if (
                    Number(value.target.value) <= Number(ev1.maxiQ3) ||
                    ev1.maxiQ3 === "" ||
                    ev1.typeQ3 === "text"
                  ) {
                    setRep3(value.target.value);
                  } else {
                    alert(
                      "merci de saisir une valeur inferieure ou egale a " +
                        ev1.maxiQ3
                    );
                  }
                }}
                value={rep3}
              />
            </div>
          </div>
        )}
        {(afficheListe || afficheParticipe) && ev1.q4 !== "" && (
          <div className="question-container">
            <div className="question">
              <label>{ev1.q4}</label>
            </div>
            <div className="response" hidden={afficheListe}>
              <TextField
                fullWidth
                label="votre Reponse"
                type={ev1.typeQ4}
                name="rep4"
                onChange={(value) => {
                  if (
                    Number(value.target.value) <= Number(ev1.maxiQ4) ||
                    ev1.maxiQ4 === "" ||
                    ev1.typeQ4 === "text"
                  ) {
                    setRep4(value.target.value);
                  } else {
                    alert(
                      "merci de saisir une valeur inferieure ou egale a " +
                        ev1.maxiQ4
                    );
                  }
                }}
                value={rep4}
              />
            </div>
          </div>
        )}
        {afficheParticipe && (
          <Button
            className="save-btn btn-simple animation-on-hover"
            disabled={bolSave}
            color="info"
            variant="contained"
            onClick={saveParticipant}
          >
            Sauvegarder
          </Button>
        )}
        {afficheListe && (
          <Table
            id="table"
            className={`table-container ${classes.table}`}
            aria-label="customized table"
          >
            <thead className="text-center">
              <tr>
                <th>nom </th>

                <th>date de mise a jour</th>

                <th>reponse Q1 </th>
                <th>reponse Q2 </th>

                <th>reponse Q3 </th>
                <th>reponse Q4 </th>
                <th>total a payer </th>

                <th></th>
              </tr>
            </thead>
            <TableBody className="table-body">
              {ev1.responses.map((ev) => (
                <StyledTableRow key={ev.repId}>
                  <StyledTableCell className="partner">
                    {ev.nameId}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {ev.dateRep}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {ev.rep1}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {ev.rep2}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {ev.rep3}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {ev.rep4}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {ev.totalRep}
                  </StyledTableCell>
                  {!ev1.statusDeverse && ev.nameId !== "TOTAL GENERAL" && (
                    <StyledTableCell className="crud-icons">
                      <i
                        onClick={() => {
                          onDelete2(ev);
                        }}
                        className="fas fa-trash-alt"
                      ></i>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
  return (
    <div>
      {openFlag && <Modal8Evennement crmId={crmId} />}
      {initCompteur()}
      <DashboardHeaderEvennements
        onAdd={onAdd}
        crmCount={compteur}
        crmId2={""}
        retour={retour}
      />

      <div className="img-header-container2 flex">
        {users.map(
          (user) =>
            user.isAdmin &&
            user.evennements &&
            user.evennements.map(
              (evennement) =>
                (evennement.status === "Actif" || loggedInUser.isManager) && (
                  <Col lg="3" md="6">
                    <Card className="card-stats" key={evennement._id}>
                      <CardBody
                        className="testCard"
                        onClick={() => onEdit2(evennement)}
                      >
                        <Row>
                          <Col xs="5">
                            <div
                              className={
                                evennement.status === "Actif"
                                  ? "info-icon text-center icon-success"
                                  : "info-icon text-center icon-danger"
                              }
                            >
                              <i style={{ fontSize: "16px" }}>
                                {calcNbPart(evennement)}
                              </i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                ev num {evennement._id}
                              </p>
                              <CardTitle tag="h4">
                                {evennement.titleEv}
                              </CardTitle>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-sound-wave" />
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                )
            )
        )}
      </div>
      {/*
      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>Titre </th>
            <th>Date fin </th>

            {/*<th>Telephone </th>
            <th>date de mise a jour</th>

            <th>Nb Inscriptions </th>
            <th>Total Evennement </th>

            <th>Solde paiement (nis) </th>
            <th>Solde reçu (nis) </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <TableBody className="table-body">
          {users.map(
            (user) =>
              user.isAdmin &&
              user.fournisseurs &&
              user.fournisseurs.map((fournisseur) => (
                <StyledTableRow key={fournisseur._id}>
                  <StyledTableCell className="partner">
                    {fournisseur.lastName}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {fournisseur.firstName}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcDeb(fournisseur)}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcCred(fournisseur)}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {calcSoldDeb(fournisseur)}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcSoldCred(fournisseur)}
                  </StyledTableCell>

                  <StyledTableCell className="crud-icons">
                    <i
                      onClick={() => {
                        onEdit2(fournisseur);
                      }}
                      className="fas fa-eye"
                    ></i>
                  </StyledTableCell>

                  {loggedInUser.isManager && (
                    <StyledTableCell className="crud-icons">
                      <i
                        onClick={() => {
                          onEdit(fournisseur._id);
                        }}
                        className="fas fa-pencil-alt"
                      ></i>
                    </StyledTableCell>
                  )}
                  {calcDeb(fournisseur) === "0" &&
                    calcCred(fournisseur) === "0" && (
                      <StyledTableCell className="crud-icons">
                        <i
                          onClick={() => {
                            onDelete(fournisseur);
                          }}
                          className="fas fa-trash-alt"
                        ></i>
                      </StyledTableCell>
                    )}
                </StyledTableRow>
              ))
          )}
        </TableBody>
      </Table>*/}
    </div>
  );
}
