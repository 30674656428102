import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  keepKiddush2,
  sendKiddush2,
  keepShlishit2,
  sendShlishit2,
  keepName,
  loadUsers,
  saveUser,
} from "../store/actions/userAction";
//import "./styles.css";
import ReactCalendar from "react-calendar";
import styled from "styled-components";
import Hebcal, { HDate } from "hebcal";
import gematriya from "gematriya";
import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";

export function GestionEcran() {
  const dispatch = useDispatch();
  const [openFlag, setOpenFlag] = useState(parseInt(0));
  const [data, setData] = useState([]);
  const [dateU, setDateU] = useState("");
  const [Ev, setEv] = useState("");
  const [bol, setBol] = useState(false);
  const [bol2, setBol2] = useState(false);
  const [haftara, setHaftara] = useState("");

  //let bol = false;
  const [focusIdx, setFocusIdx] = useState(0);
  const [titleDvar, setTitleDvar] = useState("");
  const [signature, setSignature] = useState("");
  const [dvar, setDvar] = useState("");
  const [chiour, setChiour] = useState("");
  const [zmanTefilah, setZmanTefilah] = useState("");
  const [subTitleDvar, setSubTitleDvar] = useState("");

  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const users = useSelector((state) => state.userReducer.users);
  // setOpenFlag(0);
  useEffect(async () => {
    // setOpenFlag(0);

    if (loggedInUser.isManager) {
      await dispatch(loadUsers());

      users.map((user) => {
        if (user.isAdmin) {
          setEv(user);
          setTitleDvar(user.titleDvar);
          setSubTitleDvar(user.subTitleDvar);
          setDvar(user.dvar);
          setChiour(user.chiour);
          setSignature(user.signature);
          setZmanTefilah(user.zmanTefilah);
        }
      });
    }
  }, []);

  const submit = () => {
    // console.log("costumerLastName", costumerLastName);
    Ev.titleDvar = titleDvar;
    Ev.signature = signature;
    Ev.dvar = dvar;
    Ev.subTitleDvar = subTitleDvar;
    Ev.zmanTefilah = zmanTefilah;
    Ev.chiour = chiour;
    dispatch(saveUser(Ev));
    window.location.assign("/#/");
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;
    // console.log(ev);

    setFocusIdx(1);
  };
  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "titleDvar":
        setTitleDvar(value);
        break;
      case "subTitleDvar":
        setSubTitleDvar(value);
        break;
      case "dvar":
        setDvar(value);
        break;
      case "signature":
        setSignature(value);
        break;
      case "chiour":
        setChiour(value);
        break;
      case "zmanTefilah":
        setZmanTefilah(value);
        break;

      default:
        break;
    }
  };

  //function App() {
  return (
    <div>
      <div>
        <div>
          <p>
            <br></br>
          </p>
          <TextField
            id="outlined-input"
            label="titre Dvar"
            name="titleDvar"
            style={{
              width: 800,
            }}
            onChange={onHandleInputChange}
            defaultValue={titleDvar}
            value={titleDvar}
            multiline
            rows={3}
            InputProps={{
              readOnly: false,
            }}
          />

          <TextField
            id="outlined-input"
            label="sous-titre Dvar"
            name="subTitleDvar"
            defaultValue={subTitleDvar}
            value={subTitleDvar}
            style={{
              width: 800,
            }}
            onChange={onHandleInputChange}
            multiline
            rows={3}
            InputProps={{
              readOnly: false,
            }}
          />
          <p>
            <br></br>
          </p>
          <TextField
            id="outlined-input"
            label="Dvar"
            name="dvar"
            fullWidth
            defaultValue={dvar}
            onChange={onHandleInputChange}
            value={dvar}
            multiline
            rows={15}
            InputProps={{
              readOnly: false,
            }}
          />
          <p>
            <br></br>
          </p>
          <TextField
            id="outlined-input"
            label="Signature"
            name="signature"
            defaultValue={signature}
            onChange={onHandleInputChange}
            value={signature}
            multiline
            rows={2}
            InputProps={{
              readOnly: false,
            }}
          />
          <p>
            <br></br>
          </p>
          <TextField
            id="outlined-input"
            label="horaires des cours"
            name="chiour"
            defaultValue={chiour}
            style={{
              width: 800,
            }}
            onChange={onHandleInputChange}
            value={chiour}
            multiline
            columns={1}
            rows={5}
            InputProps={{
              readOnly: false,
            }}
          />

          <TextField
            id="outlined-input"
            label="horaires des tefilot"
            name="zmanTefilah"
            defaultValue={zmanTefilah}
            onChange={onHandleInputChange}
            value={zmanTefilah}
            style={{
              width: 800,
            }}
            multiline
            rows={5}
            InputProps={{
              readOnly: false,
            }}
          />
          <p>
            <br></br>
          </p>

          <Button
            className="save-btn btn-simple animation-on-hover"
            color="success"
            variant="contained"
            onClick={() => {
              submit();
            }}
          >
            sauvegarder
          </Button>
        </div>
      </div>
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
