import React, { useState } from "react";
import { Paper, Avatar } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";

import { loadCrms, setFilterCrm } from "../store/actions/crmAction";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  Menu,
  MenuItem,
  Checkbox,
  CheckBoxOutlineBlankIcon,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Oval } from "react-loading-icons";
import classnames from "classnames";
import { DataImgsHeader } from "./DataImgsHeader";
import { DateRange } from "../cmp/DateRange";

export function DashboardHeader({ crmCount, onAdd }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [focus, setFocus] = useState(false);

  const handleGlobalFilterChange = (ev) => {
    const { value } = ev.target;
    const globFilterObj = {
      all: value,
    };
    dispatch(setFilterCrm(globFilterObj));
    //console.log(globFilterObj);
    dispatch(loadCrms(globFilterObj));
  };

  if (!loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
  return (
    <Paper elevation={20}>
      <div className="dashboard-header-container flex-col ">
        <div className="dash-header-box1 flex">
          <div className="logo-container flex-col">
            {/*  <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="" />*/}
            <h1>Liste des Donateurs</h1>
            <DataImgsHeader crmCount={crmCount} />{" "}
          </div>
        </div>
        <div className="dash-header-box flex">
          {" "}
          {loggedInUser.isManager ? (
            <div>
              <Button
                className="btn-add"
                color="info"
                variant="contained"
                onClick={onAdd}
              >
                Nouveau Donateur
              </Button>{" "}
            </div>
          ) : null}
          <ReactHTMLTableToExcel
            color="info"
            id="test-table-xls-button"
            className="btn-export-container"
            table="table"
            filename="DashboardXls"
            sheet="tablexls"
            buttonText="Export Excel"
          />
          {loggedInUser.isManager ? (
            <div>
              <InputGroup
                className={`filterBy-input global-search-input ${classnames({
                  "input-group-focus": focus,
                })}`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="Rechercher"
                  onFocus={(e) => setFocus(true)}
                  onBlur={(e) => setFocus(false)}
                  name="partner"
                  onChange={handleGlobalFilterChange}
                  className="global-search-inputText"
                />
              </InputGroup>
            </div>
          ) : null}
        </div>
      </div>
    </Paper>
  );
}
