import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { crmService } from "../services/crmService";
import { confirmAlert } from "react-confirm-alert";
import {
  loadCrms,
  removeCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import { Modal2 } from "./Modal2";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { loadUsers, saveUser, setEditUser } from "../store/actions/userAction";
import { DashboardHeaderCollecteur } from "../cmp/DashboardHeaderCollecteur";
import { DashboardHeaderFournisseurs } from "../cmp/DashboardHeaderFournisseurs";
import { Modal6Fournisseurs } from "./Modal6Fournisseurs";

export function GestionFournisseurs() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const users = useSelector((state) => state.userReducer.users);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  // const crmsSoldDeb = useSelector((state) => state.crmReducer.crmsSoldDeb);
  // const crmsSoldCred = useSelector((state) => state.crmReducer.crmsSoldCred);
  // const crmUSoldDeb = useSelector((state) => state.crmReducer.crmUSoldDeb);
  // const crmUSoldCred = useSelector((state) => state.crmReducer.crmUSoldCred);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const filterCrm = useSelector((state) => state.crmReducer.filterCrm);
  const [openFlag, setOpenFlag] = useState(false);
  const [openFlag3, setOpenFlag3] = useState(false);
  const [crmId, setCrmId] = useState("");

  let soldeTotalDebit2 = "";
  let soldeTotalCredit2 = "";

  useEffect(async () => {
    setCrmId("");
    dispatch(setEditCrm(0));
    dispatch(setEditCrmuSoldDeb(0));
    dispatch(setEditCrmuSoldCred(0));
    eventBus.on("editing", () => {
      setOpenFlag(false);
    });
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    //console.log("toto");
    await dispatch(loadUsers());
    await dispatch(loadCrms());
  }, [openFlag, openFlag3]);

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  const onEdit2 = async (user) => {
    dispatch(setEditUser(user._id));
    //  setCrmId(crm._id);
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    calcSoldCred(user);
    calcSoldDeb(user);
    dispatch(setEditCrmuSoldDeb(soldeTotalDebit2));
    dispatch(setEditCrmuSoldCred(soldeTotalCredit2));
    window.location.assign("/#/gestionfournisseurdetail");
  };

  function calcDeb(user) {
    let res = "0";
    let b = 0;
    users.map((user2) => {
      if (user2.isManager) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === user._id) {
            if (isNaN(Number(el.debit))) {
              b = 0;
            } else {
              b = Number(el.debit);
            }
            res = (Number(res) + b).toFixed(2).toString();
          }
        });
      }
    });

    return res;
  }
  function calcCred(user) {
    let res = "0";
    let b = 0;

    users.map((user2) => {
      if (user2.isManager) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === user._id) {
            if (isNaN(Number(el.credit))) {
              b = 0;
            } else {
              b = Number(el.credit);
            }
            res = (Number(res) + b).toFixed(2).toString();
          }
        });
      }
    });

    return res;
  }
  function calcSoldCred(user) {
    let res = "0";
    let resc = calcCred(user);
    let resd = calcDeb(user);
    if (Number(resc) > Number(resd)) {
      res = (Number(resc) - Number(resd)).toFixed(2).toString();
    } else {
      res = "0";
    }

    soldeTotalCredit2 = (Number(soldeTotalCredit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldCred(soldeTotalCredit2));
    return res;
  }
  function calcSoldDeb(user) {
    let res = "";
    let resd = calcDeb(user);
    let resc = calcCred(user);

    if (Number(resc) < Number(resd)) {
      res = (Number(resd) - Number(resc)).toFixed(2).toString();
    } else {
      res = "0";
    }
    soldeTotalDebit2 = (Number(soldeTotalDebit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldDeb(soldeTotalDebit2));
    return res;
  }
  const onEdit = async (rowId) => {
    /*if (crmIdxEdited == rowId) {
      alert("Someone else is editing this row, please try later");
      return;
    } else {*/
    //console.log('redux state idx',crmIdxEdited);
    dispatch(setEditCrm(rowId));
    setCrmId(rowId);
    setOpenFlag(true);
    // }
  };
  const onAdd = () => {
    setOpenFlag(true);
  };

  const onDelete = (fournisseur) => {
    confirmAlert({
      title: "confirmation de la suppression",
      message: "Cette action est irreversible, confirmez-vous la suppression ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            // data3.splice(rowKey, 1);

            users.map((user) => {
              if (user.isAdmin) {
                user.fournisseurs.map((el, index) => {
                  if (el._id === fournisseur._id) {
                    user.fournisseurs.splice(index, 1);
                    dispatch(saveUser(user));
                    setOpenFlag3(true);
                  }
                });
              }
            });
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  };

  if (!loggedInUser || !loggedInUser.isManager)
    return (
      <div>
        <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
        <h3>
          {" "}
          vous n'avez pas encore été approuvé par le responsable de
          l'application, patience ...{" "}
        </h3>
      </div>
    );
  return (
    <div>
      {openFlag && <Modal6Fournisseurs crmId={crmId} />}

      <DashboardHeaderFournisseurs onAdd={onAdd} />

      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>Nom </th>
            <th>prenom </th>

            {/*<th>Telephone </th>
            <th>date de mise a jour</th>
*/}
            <th>Total payé au fournisseur (nis) </th>
            <th>Total reçu du fournisseur (nis) </th>

            <th>Solde paiement (nis) </th>
            <th>Solde reçu (nis) </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <TableBody className="table-body">
          {users.map(
            (user) =>
              user.isAdmin &&
              user.fournisseurs &&
              user.fournisseurs.map((fournisseur) => (
                <StyledTableRow key={fournisseur._id}>
                  <StyledTableCell className="partner">
                    {fournisseur.lastName}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {fournisseur.firstName}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcDeb(fournisseur)}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcCred(fournisseur)}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {calcSoldDeb(fournisseur)}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcSoldCred(fournisseur)}
                  </StyledTableCell>

                  <StyledTableCell className="crud-icons">
                    <i
                      onClick={() => {
                        onEdit2(fournisseur);
                      }}
                      className="fas fa-eye fa-lg"
                    ></i>
                  </StyledTableCell>

                  {loggedInUser.isManager && (
                    <StyledTableCell className="crud-icons">
                      <i
                        onClick={() => {
                          onEdit(fournisseur._id);
                        }}
                        className="fas fa-pencil-alt fa-lg"
                      ></i>
                    </StyledTableCell>
                  )}
                  {calcDeb(fournisseur) === "0" &&
                    calcCred(fournisseur) === "0" && (
                      <StyledTableCell className="crud-icons">
                        <i
                          onClick={() => {
                            onDelete(fournisseur);
                          }}
                          className="fas fa-trash-alt fa-lg"
                        ></i>
                      </StyledTableCell>
                    )}
                </StyledTableRow>
              ))
          )}
        </TableBody>
      </Table>
    </div>
  );
}
