import React, { useState, useEffect } from "react";
import { eventBus } from "../services/eventBusService";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../services/userService";

import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";
import {
  loadUsers,
  saveUser,
  newPassword,
  setEditUser,
} from "../store/actions/userAction";

export function LineForm7Profil({ closeModal }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const users = useSelector((state) => state.userReducer.users);
  const [costumerFirstName, setCostumerFirstName] = useState("");
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerTelephone, setcostumerTelephone] = useState("");
  const [costumerEmail, setcostumerEmail] = useState("");
  const [costumerPassword, setcostumerPassword] = useState("");
  const [costumerNewPassword, setcostumerNewPassword] = useState("");
  const [costumerNewPassword2, setcostumerNewPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  //const [costumerDateMaj, setcostumerDateMaj] = useState("");
  const [costumerId, setcostumerId] = useState("");
  const filter = useSelector((state) => state.userReducer.filter);
  const [openFlagPassword, setOpenFlagPassword] = useState(false);
  const [focusIdx, setFocusIdx] = useState(0);

  useEffect(() => {
    getDetailesByUserId();
  }, []);

  const getDetailesByUserId = () => {
    //const data = loggedInUser;

    setCostumerFirstName(loggedInUser.firstName);
    setCostumerLastName(loggedInUser.lastName);
    setcostumerTelephone(loggedInUser.telephone);
    setcostumerEmail(loggedInUser.email);

    setcostumerId(loggedInUser._id);
  };
  const changePassword = () => {
    setOpenFlagPassword(true);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "costumerFirstName":
        setCostumerFirstName(value);
        break;

      case "costumerPassword":
        setcostumerPassword(value);
        break;
      case "costumerNewPassword":
        setcostumerNewPassword(value);
        break;
      case "costumerNewPassword2":
        setcostumerNewPassword2(value);
        break;
      case "costumerLastName":
        setCostumerLastName(value);
        break;
      case "costumerTelephone":
        setcostumerTelephone(value);
        break;
      case "costumerEmail":
        setcostumerEmail(value);
        break;
      default:
        break;
    }
  };

  const onFormSubmit = async () => {
    loggedInUser.firstName = costumerFirstName;
    loggedInUser.lastName = costumerLastName;
    loggedInUser.telephone = costumerTelephone;
    loggedInUser.email = costumerEmail.toLowerCase().trim();
    //   console.log("el", el);
    dispatch(saveUser(loggedInUser));
    const data = loggedInUser;
    if (openFlagPassword) {
      if (costumerNewPassword !== costumerNewPassword2) {
        alert("merci de confirmer le meme mot de passe");
      } else {
        data.password = costumerPassword;
        data.newPassword = costumerNewPassword;

        const rep = dispatch(newPassword(data));
        eventBus.emit("editing");
        //console.log(rep);
      }
    } else {
      // dispatch(loadUsers(filter));
      // dispatch(setEditUser(0));
      eventBus.emit("editing");
    }
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;

    setFocusIdx(1);
  };

  if (!loggedInUser) return <div>loading</div>;

  return (
    <div className="line-big-container">
      <div className="line-form-container flex">
        <div className="section-container flex-col box1">
          <div className="inputs-card flex-col">
            {openFlagPassword ? (
              <>
                <TextField
                  label="Ancien mot de passe "
                  className="uyu"
                  onFocus={onChangeFocus}
                  type={showPassword ? "text" : "password"}
                  name="costumerPassword"
                  onChange={onHandleInputChange}
                  onDoubleClick={handleClickShowPassword}
                  value={costumerPassword}
                />
                <TextField
                  label="Nouveau mot de passe"
                  onFocus={onChangeFocus}
                  type={showPassword ? "text" : "password"}
                  name="costumerNewPassword"
                  onChange={onHandleInputChange}
                  onDoubleClick={handleClickShowPassword}
                  value={costumerNewPassword}
                />
                <TextField
                  label="Confirmez nouveau mot de passe"
                  onFocus={onChangeFocus}
                  type={showPassword ? "text" : "password"}
                  name="costumerNewPassword2"
                  onChange={onHandleInputChange}
                  onDoubleClick={handleClickShowPassword}
                  value={costumerNewPassword2}
                />
              </>
            ) : (
              <>
                <TextField
                  label="Nom "
                  className="uyu"
                  onFocus={onChangeFocus}
                  type="text"
                  name="costumerLastName"
                  onChange={onHandleInputChange}
                  value={costumerLastName}
                />
                <TextField
                  label="Prénom"
                  onFocus={onChangeFocus}
                  type="text"
                  name="costumerFirstName"
                  onChange={onHandleInputChange}
                  value={costumerFirstName}
                />
                <TextField
                  label="Numero de Telephone"
                  onFocus={onChangeFocus}
                  type="text"
                  name="costumerTelephone"
                  onChange={onHandleInputChange}
                  value={costumerTelephone}
                />
                <TextField
                  label="Email"
                  onFocus={onChangeFocus}
                  type="text"
                  name="costumerEmail"
                  onChange={onHandleInputChange}
                  value={costumerEmail}
                />
                <Button
                  color="info"
                  className="btn-simple animation-on-hover close-modal-btn"
                  onClick={changePassword}
                >
                  <h5> Mot de passe</h5>
                </Button>
              </>
            )}
            <div className="btn-modal-container">
              <Button
                className="btn-simple animation-on-hover close-modal-btn"
                color="danger"
                onClick={closeModal}
              >
                X
              </Button>
              <Button
                className="save-btn btn-simple animation-on-hover"
                color="success"
                variant="contained"
                onClick={onFormSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
