import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Login } from "./pages/Login";
import { Dashboard2 } from "./pages/Dashboard2";
import { Signp } from "./pages/Signp";
import { Home } from "./pages/Home";
import { HistoDons } from "./pages/Histodons";
import { GestionUtilisateurs } from "./pages/GestionUtilisateurs";
import { HistoDonsCrm } from "./pages/Histodonscrm";
//import { UdataContext } from "./components/ReactTable/AppContext";
import axios from "axios";
import Navbar from "./pages/Navbar";
import NavbarManager from "./pages/NavbarManager";
import { GestionAffectation } from "./pages/GestionAffectation";
import { GestionCollecteurs } from "./pages/GestionCollecteur";
import { GestionCollecteurDetail } from "./pages/GestionCollecteurDetail";
import { GestionFournisseurs } from "./pages/GestionFournisseurs";
import { GestionFournisseursDetail } from "./pages/GestionFournisseursDetail";
import { Loginmdp } from "./pages/Loginmdp";
import { ReadReservation } from "./pages/ReadReservation";
import { KiddushReservation } from "./pages/KiddushReservation";
import { GestionEcran } from "./pages/Gestionecran";
import { HistoDonsgl } from "./pages/Histodonsgl";
import { HistoEvennements } from "./pages/Histoevennements";
//import { useDispatch } from "react-redux";
const BASE_URL =
  process.env.NODE_ENV === "production" ? "/api/" : "//localhost:5000/api/";

export function App() {
  /*const [Udata, setUdata] = useState(null);
/*const [Udataid, setUdataid] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    const fecthData = async () => {
      await axios({
        method: "get",
        url: `${BASE_URL}jwtid`,

        withCredentials: true,
      })
        .then((res) => {
          setUdata(res.data);
          setUdataid(res.data.id);
        })
        .catch((err) => console.log("No token"));
    };
    fecthData();
    //if (Udata) dispatch()
  }, [Udataid]);
*/
  return (
    //  <UdataContext.Provider value={Udata}>
    <div className="app-wrapper">
      <Navbar />
      <NavbarManager />
      <Switch>
        <Route component={HistoDons} exact path="/histodons" />
        <Route component={HistoDonsgl} exact path="/histodonsgl" />
        <Route component={HistoEvennements} exact path="/histoevennements" />
        <Route component={HistoDonsCrm} exact path="/histodoncrm" />
        <Route component={GestionEcran} exact path="/gestionecran" />
        <Route component={GestionAffectation} exact path="/gestaffect" />
        <Route component={GestionUtilisateurs} exact path="/gestionusers" />
        <Route component={ReadReservation} exact path="/readreservation" />
        <Route
          component={KiddushReservation}
          exact
          path="/kiddushreservation"
        />
        <Route
          component={GestionFournisseursDetail}
          exact
          path="/gestionfournisseurdetail"
        />
        <Route
          component={GestionFournisseurs}
          exact
          path="/gestionfournisseurs"
        />
        <Route
          component={GestionCollecteurDetail}
          exact
          path="/gestioncollecteurdetail"
        />
        <Route
          component={GestionCollecteurs}
          exact
          path="/gestionCollecteurs"
        />
        <Route component={Signp} exact path="/signup" />
        <Route component={Login} exact path="/login" />
        <Route component={Loginmdp} exact path="/loginmdp" />
        <Route component={Home} exact path="/" />
        <Redirect to="/" />
      </Switch>
    </div>
    //  </UdataContext.Provider>
  );
}
