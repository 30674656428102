import React, { useContext, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
//import { UdataContext } from "../components/ReactTable/AppContext";
import { Link, NavLink } from "react-router-dom";
import { clearFilterCrm } from "../store/actions/crmAction";
import { clearFilter, logout } from "../store/actions/userAction";
const NavbarManager = () => {
  const dispatch = useDispatch();
  const [focusV, setFocusV] = useState(1);
  function dohistodons() {
    dispatch(clearFilterCrm());
    window.location.assign("/#/histodons");
  }
  function dohistodonsgl() {
    dispatch(clearFilterCrm());
    window.location.assign("/#/histodonsgl");
  }
  function dohistoEvennements() {
    dispatch(clearFilterCrm());
    window.location.assign("/#/histoevennements");
  }
  function dogestionutilisateurs() {
    dispatch(clearFilter());

    window.location.assign("/#/gestionusers");
  }

  function doGestionFournisseurs() {
    // dispatch(clearFilter());

    window.location.assign("/#/gestionfournisseurs");
  }
  function doReadResa() {
    // dispatch(clearFilter());

    window.location.assign("/#/readreservation");
  }
  function dogestionecran() {
    // dispatch(clearFilter());

    window.location.assign("/#/gestionecran");
  }
  function doKiddushResa() {
    // dispatch(clearFilter());

    window.location.assign("/#/kiddushreservation");
  }
  function doGestionCollecteurs() {
    // dispatch(clearFilter());

    window.location.assign("/#/gestionCollecteurs");
  }
  function changeFocus(ev) {
    const { name } = ev.target;
    switch (name) {
      case "histodons":
        setFocusV(1);
        break;
      case "histodonsgl":
        setFocusV(8);
        break;
      case "histoevennements":
        setFocusV(9);
        break;
      case "readresa":
        setFocusV(2);
        break;
      case "kiddushresa":
        setFocusV(3);
        break;
      case "gestionfournisseurs":
        setFocusV(4);
        break;
      case "gestioncollecteurs":
        setFocusV(5);
        break;
      case "gestionutilisateur":
        setFocusV(6);
        break;
      case "gestionecran":
        setFocusV(7);
        break;
      default:
        break;
    }
  }

  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);

  if (loggedInUser) {
    return (
      <nav>
        <div className="nav-container2">
          <div className="nav-user2">
            <button
              className={focusV === 1 ? "btn btn-simple" : "btn "}
              color="success"
              name="histodons"
              onFocus={changeFocus}
              variant="contained"
              onClick={dohistodons}
            >
              {" "}
              <img
                //id="logobetyossef"
                src={process.env.PUBLIC_URL + "/assets/money.png"}
                alt="icon"
                height={"100"}
                width={"100"}
              />
              <h4>Dons</h4>
            </button>
            <button
              className={
                focusV === 2 ? "btn btn-simple" : "btn animation-on-hover"
              }
              color="success"
              onFocus={changeFocus}
              name="readresa"
              variant="contained"
              onClick={doReadResa}
            >
              <img
                //id="logobetyossef"
                src={process.env.PUBLIC_URL + "/assets/kriya.png"}
                alt="icon"
                height={"100"}
                width={"100"}
              />
              <h4>Lecture</h4>
            </button>
            <button
              className={
                focusV === 3 ? "btn btn-simple" : "btn animation-on-hover"
              }
              color="success"
              onFocus={changeFocus}
              name="kiddushresa"
              variant="contained"
              onClick={doKiddushResa}
            >
              <img
                //id="logobetyossef"
                src={process.env.PUBLIC_URL + "/assets/kiddush.png"}
                alt="icon"
                height={"100"}
                width={"100"}
              />
              <h4>Seoudot</h4>
            </button>
            <button
              className={
                focusV === 9 ? "btn btn-simple" : "btn animation-on-hover"
              }
              color="success"
              name="histoevennements"
              onFocus={changeFocus}
              variant="contained"
              onClick={dohistoEvennements}
            >
              <img
                //id="logobetyossef"
                src={process.env.PUBLIC_URL + "/assets/evennement.png"}
                alt="icon"
                height={"100"}
                width={"100"}
              />
              <h4>Evennement</h4>
            </button>
            {loggedInUser.isManager ? (
              <>
                <button
                  className={
                    focusV === 4 ? "btn btn-simple" : "btn animation-on-hover"
                  }
                  color="success"
                  onFocus={changeFocus}
                  name="gestionfournisseurs"
                  variant="contained"
                  onClick={doGestionFournisseurs}
                >
                  <img
                    //id="logobetyossef"
                    src={process.env.PUBLIC_URL + "/assets/fournisseurs.png"}
                    alt="icon"
                    height={"100"}
                    width={"100"}
                  />
                  <h4>Fournisseurs</h4>
                </button>
              </>
            ) : (
              ""
            )}
            {loggedInUser.isAdmin ? (
              <>
                <button
                  className={
                    focusV === 8 ? "btn btn-simple" : "btn animation-on-hover"
                  }
                  color="success"
                  name="histodonsgl"
                  onFocus={changeFocus}
                  variant="contained"
                  onClick={dohistodonsgl}
                >
                  <h6> grand livre</h6>
                </button>
              </>
            ) : (
              ""
            )}
            {loggedInUser.isManager ? (
              <>
                <button
                  className={
                    focusV === 5 ? "btn btn-simple" : "btn animation-on-hover"
                  }
                  color="success"
                  onFocus={changeFocus}
                  name="gestioncollecteurs"
                  variant="contained"
                  onClick={doGestionCollecteurs}
                >
                  <img
                    //id="logobetyossef"
                    src={process.env.PUBLIC_URL + "/assets/collecteurs.png"}
                    alt="icon"
                    height={"100"}
                    width={"100"}
                  />
                  <h4>Collecteurs</h4>
                </button>
              </>
            ) : (
              ""
            )}

            {loggedInUser.isManager ? (
              <>
                <button
                  className={
                    focusV === 6 ? "btn btn-simple" : "btn animation-on-hover"
                  }
                  color="success"
                  onFocus={changeFocus}
                  name="gestionutilisateur"
                  variant="contained"
                  onClick={dogestionutilisateurs}
                >
                  <img
                    //id="logobetyossef"
                    src={process.env.PUBLIC_URL + "/assets/users.png"}
                    alt="icon"
                    height={"100"}
                    width={"100"}
                  />
                  <h4>Utilisateurs</h4>
                </button>
              </>
            ) : (
              ""
            )}
            {loggedInUser.isManager ? (
              <>
                <button
                  className={
                    focusV === 7 ? "btn btn-simple" : "btn animation-on-hover"
                  }
                  color="success"
                  onFocus={changeFocus}
                  name="gestionecran"
                  variant="contained"
                  onClick={dogestionecran}
                >
                  <img
                    //id="logobetyossef"
                    src={process.env.PUBLIC_URL + "/assets/ecran.png"}
                    alt="icon"
                    height={"100"}
                    width={"100"}
                  />
                  <h4>Ecran Acceuil</h4>
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
    );
  } else {
    return "";
  }
};
export default NavbarManager;
