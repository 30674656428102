import httpService from "./httpService";
import cookie from "js-cookie";

export const userService = {
  login,
  logout,
  signup,
  query,
  getById,
  getAdmin,
  remove,
  save,
  sendPassword,
  sendCheckMail,
  keepMaftir,
  sendMaftir,
  keepParasha,
  sendParasha,
  keepKiddush,
  sendKiddush,
  keepShlishit,
  sendShlishit,
  sendEmail,
  changePassword,
  keepInfHome,
};

function query(filterBy = {}) {
  let queryStr = "?";
  for (const key in filterBy) {
    queryStr += `${key}=${filterBy[key]}&`;
  }
  //  console.log(queryStr);
  return httpService.get(`user${queryStr || ""}`);
}

function getById(userId) {
  return httpService.get(`user/${userId}`);
}
function getAdmin() {
  return httpService.get(`user/getAdmin`);
}
function remove(userId) {
  return httpService.delete(`user/${userId}`);
}

async function save(user) {
  if (user._id) {
    return httpService.put(`user/${user._id}`, user);
  } else {
    return httpService.post(`user`, user);
  }
}

async function login(userCred) {
  const user = await httpService.post("auth/login", userCred);
  return _handleLogin(user);
}
async function sendPassword(userCred) {
  const user = await httpService.post("auth/sendpassword", userCred);
  return user;
}
async function sendEmail(userCred) {
  //console.log("usercred", userCred);
  const user = await httpService.post("user/sendemail", userCred);
  return user;
}

async function sendCheckMail(userCred) {
  const user = await httpService.post("auth/sendcheckmail", userCred);
  return user;
}
async function keepShlishit() {
  const user = await httpService.get("user/keepshlishit");
  return user;
}
async function keepKiddush() {
  const user = await httpService.get("user/keepkiddush");
  return user;
}

async function sendKiddush(data) {
  // console.log(data);
  const rep = await httpService.post("user/sendkiddush", data);
  return rep;
}
async function sendShlishit(data) {
  // console.log(data);
  const rep = await httpService.post("user/sendshlishit", data);
  return rep;
}
async function keepParasha() {
  const user = await httpService.get("user/keepparasha");
  return user;
}
async function keepInfHome() {
  const user = await httpService.get("user/keepinfhome");
  return user;
}
async function keepMaftir() {
  const user = await httpService.get("user/keepmaftir");
  return user;
}

async function sendMaftir(data) {
  // console.log(data);
  const rep = await httpService.post("user/sendmaftir", data);
  return rep;
}
async function sendParasha(data) {
  // console.log(data);
  const rep = await httpService.post("user/sendparasha", data);
  return rep;
}
async function changePassword(userCred) {
  const rep = await httpService.post("auth/changepassword", userCred);
  //console.log("reponse =", rep);
  return rep;
}
async function signup(userCred) {
  // console.log(userCred);
  const user = await httpService.post("auth/signup", userCred);
  return _handleLogin(user);
}

async function logout() {
  await httpService.post("auth/logout");
  //console.log("cc");
  cookie.remove("jwt", { expires: 1 });
  sessionStorage.clear();
  // window.location.assign("/#/");
}

function _handleLogin(user) {
  // console.log(user);
  sessionStorage.setItem("user", JSON.stringify(user));
  return user;
}
