import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { LineForm2 } from '../cmp/LineForm2';
import { eventBus } from '../services/eventBusService';
import { loadCrms, removeCrm,setEditCrm } from '../store/actions/crmAction';



export function Modal2({ crmId }) {
    const dispatch = useDispatch();
    const crms = useSelector(state => state.crmReducer.crms);
    const crmIdxEdited = useSelector(state => state.crmReducer.crmIdxEdited);

    const [isShown, setIsShown] = useState(true);


    const closeModal = () => {
        setIsShown(false)
        eventBus.emit('editing')
        eventBus.emit('added')
    }

    return (
        <div>
            <div className={`modal-wrapper ${isShown ? '' : 'hide'}`} onClick={closeModal} >
                <div className="modal-content modal-container" onClick={(ev) => ev.stopPropagation()}>
                    <LineForm2 closeModal={closeModal} crmId={crmId} />
                </div>
            </div>
        </div>
    )
}
