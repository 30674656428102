import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { crmService } from "../services/crmService";
import { confirmAlert } from "react-confirm-alert";
import {
  loadCrms,
  removeCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import { Modal2 } from "./Modal2";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { loadUsers, setEditUser } from "../store/actions/userAction";
import { DashboardHeaderCollecteur } from "../cmp/DashboardHeaderCollecteur";

export function GestionCollecteurs() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const users = useSelector((state) => state.userReducer.users);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  // const crmsSoldDeb = useSelector((state) => state.crmReducer.crmsSoldDeb);
  // const crmsSoldCred = useSelector((state) => state.crmReducer.crmsSoldCred);
  // const crmUSoldDeb = useSelector((state) => state.crmReducer.crmUSoldDeb);
  // const crmUSoldCred = useSelector((state) => state.crmReducer.crmUSoldCred);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const filterCrm = useSelector((state) => state.crmReducer.filterCrm);
  const [openFlag, setOpenFlag] = useState(false);
  const [crmId, setCrmId] = useState("");

  let soldeTotalDebit2 = "";
  let soldeTotalCredit2 = "";

  useEffect(async () => {
    setCrmId("");
    dispatch(setEditCrm(0));
    dispatch(setEditCrmuSoldDeb(0));
    dispatch(setEditCrmuSoldCred(0));
    eventBus.on("editing", () => {
      setOpenFlag(false);
    });
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    //console.log("toto");
    await dispatch(loadUsers());
    await dispatch(loadCrms());
  }, [openFlag]);

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  const onEdit2 = async (user) => {
    dispatch(setEditUser(user._id));
    //  setCrmId(crm._id);
    soldeTotalDebit2 = "0";
    soldeTotalCredit2 = "0";
    calcSoldCred(user);
    calcSoldDeb(user);
    dispatch(setEditCrmuSoldDeb(soldeTotalDebit2));
    dispatch(setEditCrmuSoldCred(soldeTotalCredit2));
    window.location.assign("/#/gestioncollecteurdetail");
  };

  function calcDeb(user) {
    let res = "0";
    let b = 0;
    users.map((user2) => {
      if (user2.isManager && !(user2._id === user._id)) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === user._id) {
            if (isNaN(Number(el.credit))) {
              b = 0;
            } else {
              b = Number(el.credit);
            }
            res = (Number(res) + b).toFixed(2).toString();
          }
        });
      }
    });

    user.decaissement.map((el) => {
      if (isNaN(Number(el.debit))) {
        b = 0;
      } else {
        b = Number(el.debit);
      }
      res = (Number(res) + b).toFixed(2).toString();
    });

    return res;
  }
  function calcCred(user) {
    let res = "0";
    let b = 0;

    users.map((user2) => {
      if (user2.isManager && !(user2._id === user._id)) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === user._id) {
            if (isNaN(Number(el.debit))) {
              b = 0;
            } else {
              b = Number(el.debit);
            }
            res = (Number(res) + b).toFixed(2).toString();
          }
        });
      }
    });

    crms.map((crm) => {
      crm.saisie.forEach((el) => {
        if (el.destinataire_id === user._id) {
          res = (Number(res) + Number(el.credit)).toFixed(2).toString();
        }
      });
    });
    user.decaissement.map((el) => {
      if (isNaN(Number(el.credit))) {
        b = 0;
      } else {
        b = Number(el.credit);
      }
      res = (Number(res) + b).toFixed(2).toString();
    });
    return res;
  }
  function calcSoldCred(user) {
    let res = "0";
    let resc = calcCred(user);
    let resd = calcDeb(user);
    if (Number(resc) > Number(resd)) {
      res = (Number(resc) - Number(resd)).toFixed(2).toString();
    } else {
      res = "0";
    }

    soldeTotalCredit2 = (Number(soldeTotalCredit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldCred(soldeTotalCredit2));
    return res;
  }
  function calcSoldDeb(user) {
    let res = "";
    let resd = calcDeb(user);
    let resc = calcCred(user);

    if (Number(resc) < Number(resd)) {
      res = (Number(resd) - Number(resc)).toFixed(2).toString();
    } else {
      res = "0";
    }
    soldeTotalDebit2 = (Number(soldeTotalDebit2) + Number(res))
      .toFixed(2)
      .toString();
    dispatch(setEditCrmSoldDeb(soldeTotalDebit2));
    return res;
  }

  const onAdd = () => {
    setOpenFlag(true);
  };

  if (!crms || !loggedInUser || !loggedInUser.isApprouved)
    return (
      <div>
        <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
        <h3>
          {" "}
          vous n'avez pas encore été approuvé par le responsable de
          l'application, patience ...{" "}
        </h3>
      </div>
    );
  return (
    <div>
      {/* {openFlag && <Modal2 crmId={crmId} />}*/}

      <DashboardHeaderCollecteur />

      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>Nom </th>
            <th>prenom </th>

            {/*<th>Telephone </th>
            <th>date de mise a jour</th>
*/}
            <th>Total decaissé (nis) </th>
            <th>Total encaissé (nis) </th>

            <th>Solde Decaissement (nis) </th>
            <th>Solde Encaissement (nis) </th>
            <th></th>
          </tr>
        </thead>
        <TableBody className="table-body">
          {users.map(
            (user) =>
              user.isManager &&
              !user.isAdmin && (
                <StyledTableRow key={user._id}>
                  <StyledTableCell className="partner">
                    {user.lastName}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {user.firstName}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcDeb(user)}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcCred(user)}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {calcSoldDeb(user)}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {calcSoldCred(user)}
                  </StyledTableCell>

                  <StyledTableCell className="crud-icons">
                    <i
                      onClick={() => {
                        onEdit2(user);
                      }}
                      className="fas fa-eye fa-lg"
                    ></i>
                  </StyledTableCell>
                </StyledTableRow>
              )
          )}
        </TableBody>
      </Table>
    </div>
  );
}
