import Axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.betyossefhaim.com/api/"
    : "//localhost:5000/api/";
console.log(process.env.NODE_ENV);
var axios = Axios.create({
  withCredentials: true,
});

export default {
  get(endpoint, data) {
    return ajax(endpoint, "GET", data);
  },
  post(endpoint, data) {
    return ajax(endpoint, "POST", data);
  },
  put(endpoint, data) {
    return ajax(endpoint, "PUT", data);
  },
  delete(endpoint, data) {
    return ajax(endpoint, "DELETE", data);
  },
};

async function ajax(endpoint, method = "get", data = null) {
  try {
    //  console.log("httpservice,", endpoint, data, headers);
    const res = await axios({
      url: `${BASE_URL}${endpoint}`,
      method,

      // credentials: "include",
      data,
    });
    // console.log("httpservice", res.data);
    return res.data;
  } catch (err) {
    console.log(
      `Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data: ${data}`
    );
    console.dir(err);
    if (err.response && err.response.status === 401) {
      alert("mail ou mot de passe incorrect");
      window.location.assign("/#/login");
    }
    throw err;
  }
}
