import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { DashboardHeadercrm } from "../cmp/DashboardHeadercrm";
import { crmService } from "../services/crmService";
import { loadUsers, removeUser } from "../store/actions/userAction";
import { confirmAlert } from "react-confirm-alert"; // Import
//import "react-confirm-alert/src/react-confirm-alert.css";
import {
  loadCrms,
  removeCrm,
  saveCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import { Modal3 } from "./Modal3";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

export function HistoDonsCrm() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [openFlag2, setOpenFlag2] = useState(false);
  const [data, setData] = useState();
  const [data4, setData4] = useState();
  const [keyData, setKeyData] = useState();
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  let data3 = [];
  const sizeMini = 700;
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  useEffect(() => (window.onresize = updateSize), []);
  useEffect(() => {
    dispatch(loadUsers());
    if (crmIdxEdited) getDetailesByCrmId();
  }, [openFlag2]);

  const getDetailesByCrmId = async () => {
    setData(await crmService.getById(crmIdxEdited));
  };
  function Tridata() {
    data3 = data.saisie.slice();
    data3.sort((a, b) => {
      const first = b.dateEvent;
      const second = a.dateEvent;
      return first > second ? 1 : first < second ? -1 : 0;
    });

    const resd = calcDeb(data);
    const resc = calcCred(data);
    if (Number(resc) > Number(resd)) {
      dispatch(
        setEditCrmuSoldCred((Number(resc) - Number(resd)).toFixed(2).toString())
      );
      dispatch(setEditCrmuSoldDeb("0"));
    } else {
      dispatch(
        setEditCrmuSoldDeb((Number(resd) - Number(resc)).toFixed(2).toString())
      );
      dispatch(setEditCrmuSoldCred("0"));
    }
  }
  function calcDeb(crm) {
    let res = "";
    crm.saisie.forEach((el) => {
      res = (Number(res) + Number(el.debit)).toString();
    });
    return res;
  }
  function calcCred(crm) {
    let res = "";
    crm.saisie.forEach((el) => {
      res = (Number(res) + Number(el.credit)).toString();
    });
    return res;
  }

  useEffect(() => {
    eventBus.on("editing", () => {
      setOpenFlag2(false);
      setKeyData();
      // setCrmId("");
      //dispatch(setEditCrm(0));

      //window.location.reload();
    });
    // dispatch(loadCrms());
  }, [openFlag2]);

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 400,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  const onDelete = (rowKey) => {
    confirmAlert({
      title: "confirmation de la suppression",
      message: "Cette action est irreversible, confirmez-vous la suppression ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            data3.splice(rowKey, 1);
            data.saisie = data3;
            data.dateMaj = moment().format();
            dispatch(saveCrm(data));
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  };

  const onEdit = async (rowKey) => {
    //if (crmIdxEdited == rowId) {
    // alert("Someone else is editing this row, please try later");
    //return;
    //} else {
    //console.log('redux state idx',crmIdxEdited);
    //dispatch(setEditCrm(rowId));
    //  console.log("rowkey=", rowKey);
    setKeyData(rowKey);
    setData4(data3);
    //console.log("data3", data3);
    //console.log("keydata =", keyData);
    setOpenFlag2(true);
    // }
  };

  const onAdd = () => {
    setData4(data3);
    setOpenFlag2(true);
  };

  if (!data || !loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;

  //const filteredCrm = getFilteredTable();
  return (
    <div>
      {openFlag2 && <Modal3 data3={data4} keyData={keyData} />}
      <DashboardHeadercrm
        crmCount={data.saisie.length}
        firstName={data.firstName}
        lastName={data.lastName}
        onAdd={onAdd}
      />

      {/* <TableContainer elevation={20} className='table-container' component={Paper}> */}
      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>evennement</th>
            <th>date </th>

            <th> Debit (nis) </th>
            <th> Credit (nis) </th>
            {size.x > sizeMini && (
              <>
                <th> mode paiement </th>
                <th> collecteur </th>
              </>
            )}
            {loggedInUser.isManager && <th></th>}
            {loggedInUser.isManager && <th></th>}
          </tr>
        </thead>
        <TableBody className="table-body">
          {Tridata()}
          {data3.map((don, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell className="partner">{don.event}</StyledTableCell>

              <StyledTableCell className="partner">
                {moment(don.dateEvent).format("DD-MM-YY")}
              </StyledTableCell>

              <StyledTableCell className="partner">{don.debit}</StyledTableCell>

              <StyledTableCell className="partner">
                {don.credit}
              </StyledTableCell>
              {size.x > sizeMini && (
                <>
                  <StyledTableCell className="partner">
                    {don.mode}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {don.destinataire}
                  </StyledTableCell>
                </>
              )}
              {loggedInUser.isManager && (
                <StyledTableCell className="crud-icons">
                  <i
                    onClick={() => {
                      onEdit(index);
                    }}
                    className="fas fa-eye fa-lg"
                  ></i>
                </StyledTableCell>
              )}
              {loggedInUser.isManager && (
                <StyledTableCell className="crud-icons">
                  <i
                    onClick={() => {
                      onDelete(index);
                    }}
                    className="fas fa-trash-alt fa-lg"
                  ></i>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
