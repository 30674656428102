import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Paper, Avatar } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { loadUsers, logout, setFilter } from "../store/actions/userAction";
import { loadCrms } from "../store/actions/crmAction";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  Menu,
  MenuItem,
  Checkbox,
  CheckBoxOutlineBlankIcon,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Oval } from "react-loading-icons";
import classnames from "classnames";
import { DataImgsHeader } from "./DataImgsHeader";
import { DateRange } from "./DateRange";
import { DataImgsHeaderUtilisateur } from "./DataImgsHeaderUtilisateur";

export function DashboardHeaderUtilisateur({ crmCount, onAdd }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [focus, setFocus] = useState(false);

  /*const onLogOut = () => {
    dispatch(logout());
    window.location.assign("/#/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };*/

  const handleGlobalFilterChange = (ev) => {
    const { value } = ev.target;
    const globFilterObj = {
      all: value,
    };
    dispatch(setFilter(globFilterObj));
    //console.log(globFilterObj);
    dispatch(loadUsers(globFilterObj));
  };

  if (!loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
  return (
    <Paper elevation={20}>
      <div className="dashboard-header-container flex-col">
        <div className="dash-header-box1 flex">
          <div className="logo-container flex-col">
            {/*  <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="" />*/}
            <h1>Gestion des Utilisateurs</h1>
          </div>
          <DataImgsHeaderUtilisateur crmCount={crmCount} />
          <div className="user-box flex-col">
            {/*<Avatar
              className="avatar"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {loggedInUser
                ? loggedInUser.firstName
                    .split(" ")
                    .map(function (s) {
                      return s.charAt(0);
                    })
                    .join("")
                : ""}
            </Avatar>
            <Menu
              id="simple-menu"
              className="logout-dropdown"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onLogOut}>{loggedInUser.firstName}</MenuItem>
              <MenuItem onClick={onLogOut}>Logout</MenuItem>
                </Menu>*/}
          </div>
        </div>
        <div className="dash-header-box flex">
          {" "}
          <ReactHTMLTableToExcel
            color="info"
            id="test-table-xls-button"
            className="btn-export-container"
            table="table"
            filename="DashboardXls"
            sheet="tablexls"
            buttonText="Export Excel"
          />
          {/*{loggedInUser.isManager && (
            <Link to="/signup">
              <Button className="animation-on-hover create-btn" color="warning">
                Nouveau User
              </Button>
            </Link>
          )}*/}
          {loggedInUser.isManager ? (
            <div>
              <InputGroup
                className={`filterBy-input global-search-input ${classnames({
                  "input-group-focus": focus,
                })}`}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="Rechercher"
                  onFocus={(e) => setFocus(true)}
                  onBlur={(e) => setFocus(false)}
                  name="partner"
                  onChange={handleGlobalFilterChange}
                  className="global-search-inputText"
                />
              </InputGroup>
            </div>
          ) : null}
          {/* <DateRange />*/}
        </div>
      </div>
    </Paper>
  );
}
