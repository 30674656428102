import { combineReducers } from 'redux';

import { crmReducer } from './crmReducer';
import { userReducer } from './userReducer';

const rootReducer = combineReducers({
    crmReducer,
    userReducer
  });
  
  export default rootReducer;