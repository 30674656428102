import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Paper, Avatar } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/userAction";
import { loadCrms } from "../store/actions/crmAction";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  Menu,
  MenuItem,
  Checkbox,
  CheckBoxOutlineBlankIcon,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Oval } from "react-loading-icons";
import classnames from "classnames";
import { DataImgsHeadercrm } from "./DataImgsHeadercrm";
import { DateRange } from "./DateRange";

export function DashboardHeadercrm({ crmCount, firstName, lastName, onAdd }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [focus, setFocus] = useState(false);

  const onLogOut = () => {
    dispatch(logout());
    window.location.assign("/#/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGlobalFilterChange = (ev) => {
    const { value } = ev.target;
    const globFilterObj = {
      all: value,
    };

    //console.log(globFilterObj);
    // dispatch(loadCrms(globFilterObj));
  };

  if (!loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
  return (
    <Paper elevation={20}>
      <div className="dashboard-header-container flex-col">
        <div className="dash-header-box1 flex">
          <div className="logo-container flex-col">
            {/*  <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="" />*/}
            <h1>
              Detail des dons de : {firstName} {lastName}
            </h1>

            <DataImgsHeadercrm crmCount={crmCount} />
          </div>
          <div className="user-box flex-col">
            {/*<Avatar
              className="avatar"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {loggedInUser
                ? loggedInUser.firstName
                    .split(" ")
                    .map(function (s) {
                      return s.charAt(0);
                    })
                    .join("")
                : ""}
            </Avatar>
            <Menu
              id="simple-menu"
              className="logout-dropdown"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onLogOut}>{loggedInUser.firstName}</MenuItem>
              <MenuItem onClick={onLogOut}>Logout</MenuItem>
                </Menu>*/}
          </div>
        </div>
        <div className="dash-header-box flex">
          {" "}
          {loggedInUser.isManager ? (
            <div>
              <Button
                className="btn-add"
                color="info"
                variant="contained"
                onClick={onAdd}
              >
                Nouvelle saisie
              </Button>{" "}
            </div>
          ) : null}
          <ReactHTMLTableToExcel
            color="info"
            id="test-table-xls-button"
            className="btn-export-container"
            table="table"
            filename="DashboardXls"
            sheet="tablexls"
            buttonText="Export Excel"
          />
        </div>
      </div>
    </Paper>
  );
}
