import React, { useContext } from "react";

import { Button } from "reactstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { UdataContext } from "../components/ReactTable/AppContext";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../store/actions/userAction";
import { eventBus } from "../services/eventBusService";
import { Modal7Profil } from "./Modal7profil";

const Navbar = () => {
  const dispatch = useDispatch();
  const [openFlagProfil, setOpenFlagProfil] = useState(false);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  useEffect(async () => {
    eventBus.on("editing", () => {
      setOpenFlagProfil(false);
    });
  }, [openFlagProfil]);

  const dologout = (ev) => {
    ev.preventDefault();
    dispatch(logout());
    window.location.assign("/#/");
  };

  const doModifProfil = (ev) => {
    ev.preventDefault();
    setOpenFlagProfil(true);
  };

  return (
    <div>
      <nav>
        {openFlagProfil && <Modal7Profil />}
        <div className="nav-container">
          <NavLink exact to="/">
            <div className="navlogo-container">
              <img
                id="logobetyossef"
                src={process.env.PUBLIC_URL + "/assets/bet_yossef_haim.png"}
                alt="icon"
              />

              <h1>Bet Yossef Haim </h1>
            </div>
          </NavLink>

          <a
            target="_blank"
            href="https://payboxapp.page.link/tu2QQAFkW4kg694c8"
          >
            <Button
              className=" btn-simple animation-on-hover"
              color="info"
              variant="contained"
              onClick={() => {
                " ";
              }}
            >
              <i class=" fab fa-paybox"></i> faire un don (payBox)
            </Button>
          </a>
          {loggedInUser ? (
            <div className="nav-user">
              <h3 className="nav-user1">Bienvenue {loggedInUser.firstName} </h3>
              <button className="btn" onClick={doModifProfil}>
                modifier votre profil
              </button>

              <button className="btn" color="success" onClick={dologout}>
                vous deconnecter ?
              </button>
            </div>
          ) : (
            <div className="nav-user">
              <div>
                <NavLink exact to="/login">
                  <button className="btn" color="success">
                    vous connecter ?
                  </button>
                  {/*<h3 className="nav-user1"> connectez-vous...</h3>*/}
                </NavLink>
              </div>
              {/* <div>
              <NavLink exact to="/signup">
                <h4> inscrivez-vous...</h4>
              </NavLink>
           </div> */}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
