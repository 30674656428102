import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow, Switch } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { crmService } from "../services/crmService";
import {
  loadCrms,
  removeCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import {
  clearFilter,
  loadUsers,
  removeUser,
  saveUser,
  setEditUser,
} from "../store/actions/userAction";
import { Modal2 } from "./Modal2";
import classnames from "classnames";
import { confirmAlert } from "react-confirm-alert";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { DashboardHeaderUtilisateur } from "../cmp/DashboardHeaderUtilisateur";
import { Modal4 } from "./Modal4gestionU";

export function GestionUtilisateurs() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userReducer.users);
  const filter = useSelector((state) => state.userReducer.filter);
  const crms = useSelector((state) => state.crmReducer.crms);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  const userIdxEdited = useSelector((state) => state.userReducer.userIdxEdited);

  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [openFlag, setOpenFlag] = useState(false);
  const [userId, setUserId] = useState("");
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  //let soldeTotalDebit2 = "";
  // let soldeTotalCredit2 = "";
  const sizeMini = 700;

  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  useEffect(() => (window.onresize = updateSize), []);
  useEffect(async () => {
    //nonApprouvedCount = 0;

    setUserId("");
    dispatch(setEditUser(0));

    // dispatch(setEditCrmuSoldDeb(0));
    // dispatch(setEditCrmuSoldCred(0));
    eventBus.on("editing", () => {
      setOpenFlag(false);
    });
    // soldeTotalDebit2 = "0";
    //  soldeTotalCredit2 = "0";
    await dispatch(loadCrms());
    await dispatch(loadUsers(filter));
  }, [openFlag]);

  function Tridata() {
    users.sort((a, b) => {
      const first = a.lastName;
      const second = b.lastName;
      return first > second ? 1 : first < second ? -1 : 0;
    });
  }

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 400,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  const onDelete = (rowId) => {
    confirmAlert({
      title: "confirmation de la suppression",
      message: "Cette action est irreversible, confirmez-vous la suppression ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            // data3.splice(rowKey, 1);
            dispatch(removeUser(rowId));
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  };

  const onEdit = async (rowId) => {
    if (userIdxEdited == rowId) {
      alert("Someone else is editing this row, please try later");
      return;
    } else {
      dispatch(setEditUser(rowId));
      setOpenFlag(true);
      setUserId(rowId);
      // }
    }
  };

  const changeStatusApprouved = async (user) => {
    //console.log("user", userId);

    if (user.isApprouved) {
      user.isApprouved = false;
    } else {
      user.isApprouved = true;
    }
    /* users.map((el) => {
      if (el._id === userId) {
        if (el.isApprouved) {
          el.isApprouved = false;
        } else {
          el.isApprouved = true;
        }
        // console.log("el", el);*/
    dispatch(saveUser(user));
    dispatch(loadUsers(filter));
    /*  }
    });*/
  };

  const changeStatusManager = async (userId) => {
    //console.log("user", userId);
    users.map((el) => {
      if (el._id === userId) {
        if (el.isManager) {
          el.isManager = false;
        } else {
          el.isManager = true;
        }
        // console.log("el", el);
        dispatch(saveUser(el));
        dispatch(loadUsers(filter));
      }
    });
  };

  const onEdit2 = async (user) => {
    dispatch(setEditUser(user._id));

    // soldeTotalDebit2 = "0";
    //soldeTotalCredit2 = "0";
    //calcSoldCred(crm);
    //  calcSoldDeb(crm);
    // dispatch(setEditCrmuSoldDeb(soldeTotalDebit2));
    // dispatch(setEditCrmuSoldCred(soldeTotalCredit2));
    window.location.assign("/#/gestaffect");
  };

  const onAdd = () => {
    setOpenFlag(true);
  };

  if (!users || !loggedInUser.isManager)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;

  return (
    <div>
      {openFlag && <Modal4 userId={userId} />}

      <DashboardHeaderUtilisateur crmCount={users.length} onAdd={onAdd} />

      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>Nom </th>
            <th>prenom </th>
            {size.x > sizeMini && (
              <>
                <th>Telephone </th>
                <th>Email</th>

                <th>Statut Manager ?</th>

                <th>Dependances </th>
              </>
            )}
            <th>Utilsateur Approuvé ? </th>
            <th></th>
            {size.x > sizeMini && (
              <>
                {loggedInUser.isManager && <th></th>}
                {loggedInUser.isManager && <th></th>}
              </>
            )}
          </tr>
        </thead>
        <TableBody className="table-body">
          {Tridata()}

          {users.map((user) => (
            <StyledTableRow key={user._id}>
              <StyledTableCell className="partner">
                {user.lastName}
              </StyledTableCell>

              <StyledTableCell className="partner">
                {user.firstName}
              </StyledTableCell>
              {size.x > sizeMini && (
                <>
                  <StyledTableCell className="partner">
                    {user.telephone}
                  </StyledTableCell>
                  <StyledTableCell className="partner">
                    {user.email}
                  </StyledTableCell>

                  <StyledTableCell
                    className="partner"
                    onDoubleClick={
                      loggedInUser.isAdmin &&
                      (() => changeStatusManager(user._id))
                    }
                  >
                    {user.isManager ? "Manager" : "Utilisateur"}
                  </StyledTableCell>

                  <StyledTableCell className="partner">
                    {user.crmId.map((crm) => (
                      <p>
                        {crm
                          ? crms.map((el) =>
                              el._id === crm
                                ? el.firstName + " " + el.lastName
                                : ""
                            )
                          : ""}
                      </p>
                    ))}
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell
                className="partner"
                onClick={!user.isAdmin && (() => changeStatusApprouved(user))}
              >
                <i
                  className={
                    user.isApprouved
                      ? "fa fa-toggle-on fa-lg"
                      : "fa fa-toggle-off fa-lg"
                  }
                ></i>
                {/*} {user.isApprouved ? "Approuvé" : "Non Approuvé"}*/}
              </StyledTableCell>

              <StyledTableCell className="crud-icons">
                <i
                  onClick={() => {
                    onEdit2(user);
                  }}
                  className={!user.isManager && "fas fa-eye fa-lg"}
                ></i>
              </StyledTableCell>

              {loggedInUser.isManager && size.x > sizeMini && (
                <StyledTableCell className="crud-icons">
                  <i
                    onClick={() => {
                      onEdit(user._id);
                    }}
                    className={
                      (!user.isAdmin || loggedInUser.isAdmin) &&
                      "fas fa-pencil-alt fa-lg"
                    }
                  ></i>
                </StyledTableCell>
              )}
              {loggedInUser.isManager && size.x > sizeMini && (
                <StyledTableCell className="crud-icons">
                  <i
                    onClick={() => {
                      onDelete(user._id);
                    }}
                    className={
                      (!user.isManager || loggedInUser.isAdmin) &&
                      !user.isAdmin &&
                      "fas fa-trash-alt fa-lg"
                    }
                  ></i>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
