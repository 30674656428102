import React, { useEffect } from "react";
import icons from "../assets/variables/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap";

export function DataImgsHeader({ crmCount }) {
  const crmsSoldDeb = useSelector((state) => state.crmReducer.crmsSoldDeb);
  const crmsSoldCred = useSelector((state) => state.crmReducer.crmsSoldCred);
  //console.log("crmsSoldDeb", crmsSoldDeb);
  // console.log("crmsSoldCred", crmsSoldCred);
  // useEffect(() => {}, [crmsSoldDeb || crmsSoldCred]);

  function AffcrmsolDeb() {
    if (crmCount > 0) {
      if (crmsSoldDeb - crmsSoldCred > 0) {
        return (crmsSoldDeb - crmsSoldCred).toFixed(2);
      } else {
        return "0";
      }
    } else {
      return "0";
    }
  }

  function AffcrmsolCred() {
    if (crmCount > 0) {
      if (crmsSoldCred - crmsSoldDeb > 0) {
        return (crmsSoldCred - crmsSoldDeb).toFixed(2);
      } else {
        return "0";
      }
    } else {
      return "0";
    }
  }
  return (
    <div className="img-header-container flex">
      <Col lg="3" md="6">
        <Card className="card-stats">
          <CardBody className="testCard">
            <Row>
              <Col xs="5">
                <div className="info-icon text-center icon-success">
                  <i className="far fa-user"></i>
                </div>
              </Col>
              <Col xs="7">
                <div className="numbers">
                  <p className="card-category">Nb pers Affiliés</p>
                  <CardTitle tag="h4">{crmCount}</CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-sound-wave" />
            </div>
          </CardFooter>
        </Card>
      </Col>

      {/* <Card style={{ width: '18rem' }} className='header-card card1 '>
                <CardImg className="kl" style={{ width: '60px', height: '50px', margin: '0 auto' }} src={process.env.PUBLIC_URL + '/assets/new1.png'} alt="..." />
                <CardBody>
                    <div className='text1 flex-col'>
                        <p className="cardTxt">Number Of Partners</p>
                        <span>{crmCount}</span>
                    </div>
                </CardBody>
                <hr />
            </Card> */}

      <Col lg="3" md="6">
        <Card className="card-stats">
          <CardBody className="testCard">
            <Row>
              <Col xs="5">
                <div className="info-icon text-center icon-success">
                  {/* <i class="far fa-user"></i> */}
                </div>
              </Col>
              <Col xs="7">
                <div className="numbers">
                  <p className="card-category"> Solde CREDIT</p>
                  <CardTitle tag="h4">{AffcrmsolCred()}</CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-sound-wave" />
            </div>
          </CardFooter>
        </Card>
      </Col>

      <Col lg="3" md="6">
        <Card className="card-stats">
          <CardBody className="testCard">
            <Row>
              <Col xs="5">
                <div className="info-icon text-center icon-danger">
                  {/* <i class="far fa-user"></i> */}
                </div>
              </Col>
              <Col xs="7">
                <div className="numbers">
                  <p className="card-category">Solde DEBIT</p>
                  <CardTitle tag="h4">{AffcrmsolDeb()}</CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-sound-wave" />
            </div>
          </CardFooter>
        </Card>
      </Col>

      {/* <Card style={{ width: '18rem' }} className='header-card card2'>
                <CardImg className="kl" style={{ width: '60px', height: '50px', margin: '0 auto' }} src={process.env.PUBLIC_URL + '/assets/new2.png'} alt="..." />
                <CardBody>
                    <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-shape-star" />
                    </div>
                    <div className='text1 flex-col'>
                        <p className="cardTxt">Date</p>
                        <span>22/07/2521</span>
                    </div>
                </CardBody>
            </Card>


            <Card style={{ width: '18rem' }} className='header-card card3'>
                <CardImg className="kl" style={{ width: '60px', height: '50px', margin: '0 auto' }} src={process.env.PUBLIC_URL + '/assets/new3.png'} alt="..." />
                <CardBody>
                    <div className='text1 flex-col'>
                        <p className="cardTxt">Date</p>
                        <span>22/07/2521</span>
                    </div>
                </CardBody>
            </Card> */}
    </div>
  );
}
