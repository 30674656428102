import React, { useState, useEffect } from "react";
import { eventBus } from "../services/eventBusService";
import { useDispatch, useSelector } from "react-redux";
import { crmService } from "../services/crmService";
import { saveCrm, setEditCrm } from "../store/actions/crmAction";
import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { saveUser } from "../store/actions/userAction";

export function LineForm6Fournisseurs({ closeModal, crmId }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const users = useSelector((state) => state.userReducer.users);
  const [costumerFirstName, setCostumerFirstName] = useState("");
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerTelephone, setcostumerTelephone] = useState("");
  const [costumerSaisie, setcostumerSaisie] = useState([]);
  const [costumerDateMaj, setcostumerDateMaj] = useState("");
  const [costumerId, setcostumerId] = useState("");

  const [chosenBox1, setChosenBox1] = useState(0);
  const [chosenBox2, setChosenBox2] = useState(0);
  const [chosenBox3, setChosenBox3] = useState(0);
  const [chosenBox4, setChosenBox4] = useState(0);

  const [colorFlag1, setColorFlag1] = useState(false);
  const [colorFlag2, setColorFlag2] = useState(false);
  const [colorFlag3, setColorFlag3] = useState(false);
  const [colorFlag4, setColorFlag4] = useState(false);
  const [focusIdx, setFocusIdx] = useState(0);
  const [longTab, setLongTab] = useState(0);
  //const [data, setData] = useState([]);
  const [userAdmin, setUserAdmin] = useState({});
  const [positionfF, setPositionfF] = useState(0);
  let data;
  let positionf;

  useEffect(() => {
    users.map((user) => {
      if (user.isAdmin) {
        setUserAdmin(user);
        // console.log("userAdmin", user);
        setLongTab(
          Number(user.fournisseurs[user.fournisseurs.length - 1]._id) + 1
        );
        //setData(
        data = user.fournisseurs.find(
          (fournisseur) => fournisseur._id === crmId
        );
        positionf = user.fournisseurs.findIndex(
          (fournisseur) => fournisseur._id === crmId
        );
        setPositionfF(positionf);
        //  console.log("positionf :", positionf);
        //);
      }
    });
    if (crmId) getDetailesByCrmId();
    // console.log("data0", data.firstName);
    //console.log("crmid1", crmId);
  }, []);

  const getDetailesByCrmId = () => {
    // console.log("data", data.firstName);

    setCostumerFirstName(data.firstName);
    setCostumerLastName(data.lastName);
    setcostumerTelephone(data.telephone);
    //setcostumerSaisie(data.saisie);
    // setcostumerDateMaj(data.dateMaj);
    setcostumerId(data._id);
  };

  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "costumerFirstName":
        setCostumerFirstName(value);
        break;
      case "costumerLastName":
        setCostumerLastName(value);
        break;
      case "costumerTelephone":
        setcostumerTelephone(value);
        break;

      default:
        break;
    }
  };

  const onFormSubmit = () => {
    const newCrmObj = {
      _id: costumerId ? costumerId : longTab.toString(),

      firstName: costumerFirstName,
      lastName: costumerLastName,
      telephone: costumerTelephone,
      //saisie: costumerSaisie,
      // dateMaj: moment().format(),
    };
    // console.log("userAdmin2", userAdmin);
    if (costumerId) {
      // console.log("positionf pour splice", positionfF);
      userAdmin.fournisseurs.splice(positionfF, 1, newCrmObj);
    } else {
      userAdmin.fournisseurs.push(newCrmObj);
    }

    dispatch(saveUser(userAdmin));
    dispatch(setEditCrm(0));
    eventBus.emit("editing");
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;
    //console.log(ev);

    setFocusIdx(1);
  };

  if (!loggedInUser) return <div>loading</div>;

  return (
    <div className="line-big-container">
      <div className="line-form-container flex">
        <div className="section-container flex-col box1">
          <Button
            color="info"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>Founisseur</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card flex-col">
            <TextField
              label="Nom fournisseur"
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="costumerLastName"
              onChange={onHandleInputChange}
              value={costumerLastName}
            />
            <TextField
              label="Prénom "
              onFocus={onChangeFocus}
              type="text"
              name="costumerFirstName"
              onChange={onHandleInputChange}
              value={costumerFirstName}
            />
            <TextField
              label="Numero de Telephone"
              onFocus={onChangeFocus}
              type="text"
              name="costumerTelephone"
              onChange={onHandleInputChange}
              value={costumerTelephone}
            />
            <div className="btn-modal-container">
              <Button
                className="btn-simple animation-on-hover close-modal-btn"
                color="danger"
                onClick={closeModal}
              >
                X
              </Button>
              <Button
                className="save-btn btn-simple animation-on-hover"
                color="success"
                variant="contained"
                onClick={onFormSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
