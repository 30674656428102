import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  signup,
  sendCheckMail,
  removeUser,
  loadUsers,
  sendEmail,
} from "../store/actions/userAction";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

export function Signp() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [showPassword, setShowPassword] = useState(false);
  /*signup cred*/
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password2, setPassword2] = useState("");
  const [password, setPassword] = useState("");
  const [telephone, setTelephone] = useState("");
  const [lastName, setLastName] = useState("");
  const [newUser, setNewUser] = useState(null);
  const [responseBack, setResponseBack] = useState(null);
  const [responseFront, setResponseFront] = useState("");
  const [openFlagCheckMail, setOpenFlagCheckMail] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onSignupHandleChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "signup-firstName") setFirstName(value);
    if (name === "signup-code") setResponseFront(value);
    if (name === "signup-lastName") setLastName(value);
    if (name === "signup-mail") setEmail(value);
    if (name === "signup-password") setPassword(value);
    if (name === "signup-password2") setPassword2(value);

    if (name === "telephone") setTelephone(value);
  };

  const doSignup = async (ev) => {
    ev.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !password ||
      !password2 ||
      !telephone ||
      (!email && password !== password2)
    ) {
      window.location.assign("/#/signup");
      alert("All inputs are required!");
    } else if (password !== password2) {
      window.location.assign("/#/signup");
      alert("not the same password");
    } else {
      const signUpObj = {
        firstName: firstName,
        email: email,
        password: password,
        telephone: telephone,
        isManager: false,
        lastName: lastName,
        crmId: [],
        isAdmin: false,
        isApprouved: false,
        decaissement: [],
      };

      setNewUser(signUpObj);
      const resp = await dispatch(sendCheckMail(signUpObj));
      //console.log(resp);
      setResponseBack(resp);
      setOpenFlagCheckMail(true);
    }
  };
  const doSignup2 = async (ev) => {
    if (responseBack === responseFront) {
      await dispatch(signup(newUser));
      sendEmail({
        to: "betyossefhaim.gs@gmail.com",
        subject: "nouvel utilisateur connecté",
        text:
          firstName +
          " " +
          lastName +
          " s'est connecté, merci d'approuver ou non sa connection",
      });
      //dispatch(login(signUpObj2));
      window.location.assign("/#/");
    } else {
      alert("le code saisi n'est pas correct");
    }
  };

  return (
    <div className="inputs-container flex card-primary signup-card-container">
      <Card
        style={{ width: "40rem" }}
        className="signUp-inputs login-card flex-col"
      >
        <CardImg
          top
          src={require("../assets/img/card-success.png").default}
          alt="..."
        />
        <CardBody>
          <h2 className="signup-title">Inscription</h2>

          <div className="flex-col signup-inputs-container">
            {openFlagCheckMail ? (
              <>
                <input
                  type="text"
                  className="loginInput sign-firstName form-control"
                  name="signup-code"
                  value={responseFront}
                  onChange={onSignupHandleChange}
                  placeholder="entrez le code reçu par Email"
                />
                <Button
                  color="success"
                  className="signupbtn animation-on-hover"
                  onClick={doSignup2}
                >
                  Verifier le Code
                </Button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  className="loginInput sign-firstName form-control"
                  name="signup-firstName"
                  value={firstName}
                  onChange={onSignupHandleChange}
                  placeholder="Prenom"
                />
                <input
                  type="text"
                  className="loginInput sign-lastName form-control"
                  name="signup-lastName"
                  value={lastName}
                  onChange={onSignupHandleChange}
                  placeholder="Nom de famille"
                />
                <input
                  type="text"
                  className="loginInput sign-mail form-control"
                  name="signup-mail"
                  value={email}
                  onChange={onSignupHandleChange}
                  placeholder="Email"
                />
                <input
                  type="text"
                  className="loginInput sign-tel form-control"
                  name="telephone"
                  value={telephone}
                  onChange={onSignupHandleChange}
                  placeholder="Telephone"
                />
                <input
                  type={showPassword ? "text" : "password"}
                  className="loginInput sign-pass form-control"
                  name="signup-password"
                  value={password}
                  onDoubleClick={handleClickShowPassword}
                  onChange={onSignupHandleChange}
                  placeholder="Password"
                />
                <input
                  type={showPassword ? "text" : "password"}
                  className="loginInput sign-pass form-control"
                  name="signup-password2"
                  value={password2}
                  onDoubleClick={handleClickShowPassword}
                  onChange={onSignupHandleChange}
                  placeholder="confirm Password"
                />

                <Button
                  color="success"
                  className="signupbtn animation-on-hover"
                  onClick={doSignup}
                >
                  S'inscrire
                </Button>
              </>
            )}
            {/* <Link className="login-link" to="/login">
              <Button className="btn-link" color="danger">
                deja un compte? connectez-vous...
              </Button>
  </Link>*/}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
