import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import moment from "moment";
import ReactCalendar from "react-calendar";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import Hebcal, { HDate } from "hebcal";
import gematriya from "gematriya";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
  signup,
  removeUser,
  loadUsers,
  keepInfHome,
} from "../store/actions/userAction";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import icons from "../assets/variables/icons";
export function Home() {
  const [datar, setDatar] = useState([]);
  const [datar2, setDatar2] = useState([]);
  const [afficheShab, setAfficheShab] = useState(1);
  const [titleDvar, setTitleDvar] = useState("");
  const [signature, setSignature] = useState("");
  const [dvar, setDvar] = useState("");
  const [chiour, setChiour] = useState("");
  const [zmanTefilah, setZmanTefilah] = useState("");
  const [subTitleDvar, setSubTitleDvar] = useState("");
  // let datar = [];
  const [title, setTitle] = useState("");
  const useStyles = makeStyles({
    input: {
      color: "black",
    },
  });
  const classes = useStyles();
  const Calendar = styled(ReactCalendar)`
    width: 100%;
    margin: auto;

    text-align: center;

    abbr {
      color: rgb(0, 0, 0);
    }
    .react-calendar__month-view__weekdays__weekday {
      text-align: center;
      background: rgb(125, 125, 66);
    }
    .react-calendar__navigation__label {
      height: 30px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
      width: 20px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
      width: 30px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      width: 20px;
    }
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
      width: 30px;
    }
    .react-calendar__month-view {
      .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.3;
      }
      .free {
        background-color: green;
      }
      .week {
        background-color: white;
      }
      .react-calendar__tile--active {
        border: 2px solid blue;
        z-index: 100;
      }
      .busy {
        background-color: red;
      }
      .onAttent {
        background-color: yellow;
      }
      .react-calendar__tile {
        height: 60px;
        position: relative;

        &--now {
          border: 2px solid red;
          z-index: 100;
        }

        abbr {
          display: none;
          font-size: 1.2em;
        }

        .tile {
          &__hdate {
            position: absolute;
            top: 0;
            right: 0;
          }

          &__daf {
            font-size: 0.8em;
          }
        }
      }
    }
  `;

  const DateTile = ({ activeStartDate, date, view }) => {
    //Hebcal.HDate.prototype.setLocation(32.083, 34.766);
    const hDate = Hebcal.HDate(date).setLocation(32.081216, 34.851012);
    // console.log(gematriya(hDate.getDate()));

    const daf = hDate.dafyomi();
    const title =
      date.toDateString().slice(3) + "\n" + hDate.toString("h") + "\n" + daf;

    // const gDate = date.getDate();

    return (
      view === "month" && (
        <div title={title}>
          <div
            css={`
              text-align: center;
            `}
          >
            {date.getDate()} &middot;
            {gematriya(hDate.getDate())}
          </div>
          <div className="tile__daf">{hDate.dafyomi("h")}</div>
          <div>
            {date.getDay() === 5 ? (
              hDate.candleLighting().toLocaleTimeString()
            ) : // <>&nbsp;</>
            date.getDay() === 6 && hDate.isSedra() ? (
              hDate.getSedra("h")
            ) : (
              <>&nbsp;</>
            )}
          </div>
        </div>
      )
    );
  };

  async function getShab(method = "get") {
    try {
      const res = await axios({
        url: "https://www.hebcal.com/shabbat?cfg=json&geonameid=294981&M=on&lg=fr&a=off&leyning=on&b=18",
        method: "GET",
      });
      // console.log(res);
      return res;
    } catch (err) {
      console.log(
        `Had Issues ${method}ing to the backend, endpoint:, with data: `
      );
      console.dir(err);
      if (err.response && err.response.status === 401) {
        window.location.assign("/#/login");
      }
      throw err;
    }
  }
  async function getInfHome() {
    try {
      const res = await keepInfHome();
      return res;
    } catch (err) {
      console.log(`Had Issues Geting to the backend, endpoint:, with data: `);
      console.dir(err);
      if (err.response && err.response.status === 401) {
        window.location.assign("/#/login");
      }
      throw err;
    }
  }

  async function getDayHol(method = "get") {
    try {
      const res = await axios({
        url:
          "https://www.hebcal.com/zmanim?cfg=json&geonameid=294981&date=" +
          moment().format("YYYY-MM-DD").toString(),
        // https://www.hebcal.com/zmanim?cfg=json&geonameid=3448439&date=2021-03-23
        method: "GET",
      });
      // console.log(res);
      return res;
    } catch (err) {
      console.log(
        `Had Issues ${method}ing to the backend, endpoint:, with data: `
      );
      console.dir(err);
      if (err.response && err.response.status === 401) {
        window.location.assign("/#/login");
      }
      throw err;
    }
  }

  async function valShab() {
    const rep = await getShab();
    const rep2 = await getDayHol();
    const rep3 = await keepInfHome();
    // datar = rep.data.items;
    // console.log("rep3", rep3);
    setTitleDvar(rep3[0]);
    setSubTitleDvar(rep3[1]);
    setDvar(rep3[2]);
    setChiour(rep3[4]);
    setSignature(rep3[3]);
    setZmanTefilah(rep3[5]);
    /*const alphaNumOut = Object.keys(rep2.data.times).map((key) => [
      key,
      rep2.data.times[key],
    ]);*/
    setDatar2(rep2.data.times);
    setDatar(rep.data.items);
    setTitle(rep.data.location.title);
  }
  const main = document.querySelector(".main");

  document.addEventListener("mousedown", (e) => {
    // set mousemove-X
    if (e.target.className == "drag-x" && window.event != null) {
      document.onmousemove = (e) => {
        // e == null ? (e = window.event) : "";
        document.querySelector(".box-1").style.flex =
          "0 0" + e.clientX / (main.clientWidth / 100) + "%";
      };
      preventBehavior(e);
    }
    // set mousemove-Y
    else if (e.target.className == "drag-y" && window.event != null) {
      document.onmousemove = (e) => {
        // e == null ? (e = window.event) : "";
        document.querySelector(".top").style.flex =
          "0 0" + e.clientY / (main.clientHeight / 100) + "%";
      };
      preventBehavior(e);
    }
  });
  // stop everthing on mouseup
  document.addEventListener("mouseup", (e) => {
    if (e.target != null) {
      document.onmousemove = null;
      document.onselectstart = null;
      e.target.ondragstart = null;
    }
  });
  // prevent bad behavior on the moving element
  const preventBehavior = (e) => {
    document.onselectstart = () => {
      return false;
    };
    e.target.ondragstart = () => {
      return false;
    };
    return false;
  };
  useEffect(() => {
    valShab();
  }, []);
  return (
    <>
      <div className="main row">
        <div className="top">
          <div className="box-1 col-lg-8">
            <div className="box-1-container">
              <h1>{titleDvar}</h1>

              <h2>{subTitleDvar}</h2>
              <TextField
                id="outlined-input"
                inputProps={{ className: classes.input }}
                name="dvar"
                fullWidth
                defaultValue={dvar}
                value={dvar}
                multiline
                InputProps={{
                  readOnly: true,
                }}
              />

              <h2>{signature}</h2>
            </div>
          </div>
          <span className="drag-x" title="Cliquez pour recadrer"></span>
          <div className="box-2 col-lg-4"></div>
        </div>

        <div className="drag-y" title="Cliquez pour recadrer"></div>
        <div className="bottom">
          <div className="bottom-container">
            <div className="col-lg-4">
              <Button
                className={
                  afficheShab === 1
                    ? " btn animation-on-hover"
                    : " btn-simple animation-on-hover"
                }
                color="info"
                variant="contained"
                onClick={() => {
                  setAfficheShab(1);
                }}
              >
                Horaires du jour
              </Button>
              <Button
                className={
                  afficheShab === 3
                    ? " btn animation-on-hover"
                    : " btn-simple animation-on-hover"
                }
                color="info"
                variant="contained"
                onClick={() => {
                  setAfficheShab(3);
                }}
              >
                Horaires des tefilot
              </Button>
              <Button
                className={
                  afficheShab === 2
                    ? " btn animation-on-hover"
                    : " btn-simple animation-on-hover"
                }
                color="info"
                variant="contained"
                onClick={() => {
                  setAfficheShab(2);
                }}
              >
                Horaires de Shabbat
              </Button>
              <Button
                className={
                  afficheShab === 4
                    ? " btn animation-on-hover"
                    : " btn-simple animation-on-hover"
                }
                color="info"
                variant="contained"
                onClick={() => {
                  setAfficheShab(4);
                }}
              >
                Horaires des cours
              </Button>
              {afficheShab === 2 ? (
                <>
                  <h3>Horaires de shabbat à {title}</h3>
                  {datar.map((el) => (
                    <h4>
                      {el.title} {moment(el.date).format("DD-MM-YYYY")}{" "}
                      {el.hebrew}
                    </h4>
                  ))}
                </>
              ) : (
                " "
              )}
              {afficheShab === 1 ? (
                <>
                  <h3>Horraires du {moment().format("DD-MM-YYYY")}</h3>
                  {Object.keys(datar2).map((el) => (
                    <div>
                      {el} {moment(datar2[el]).locale("FR").format("kk:mm")}{" "}
                      <br />
                    </div>
                  ))}
                </>
              ) : (
                " "
              )}
              {afficheShab === 3 ? (
                <>
                  <h3>Horaires des Tefilot</h3>

                  <TextField
                    id="outlined-input"
                    name="zmanTefilah"
                    defaultValue={zmanTefilah}
                    value={zmanTefilah}
                    fullWidth
                    multiline
                    inputProps={{ className: classes.input }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </>
              ) : (
                " "
              )}
              {afficheShab === 4 ? (
                <>
                  <h3>Horaires des Cours</h3>
                  <TextField
                    id="outlined-input"
                    name="chiour"
                    defaultValue={chiour}
                    fullWidth
                    value={chiour}
                    inputProps={{ className: classes.input }}
                    multiline
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </>
              ) : (
                " "
              )}
            </div>

            <div className="col-lg-6">
              <Calendar
                calendarType="Hebrew"
                //maxDate={addMonths(new Date(), 6)}
                //onChange={checkDate}
                //value={dateU}
                tileContent={DateTile}
                //tileClassName={defTileClassName}
                showFixedNumberOfWeeks
                showNeighboringMonth={true}
                formatShortWeekday={(locale, date) => date.toDateString()[0]}
              />
            </div>
            <div className="col-lg-2">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCcKDN0SnRyrNo9sKIiMz8lA/videos"
              >
                <Button
                  className=" btn-simple animation-on-hover"
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    " ";
                  }}
                >
                  <i class=" fab fa-youtube"></i> visiter notre chaine youtube
                </Button>
              </a>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d845.1405122315566!2d34.8513847!3d32.0810921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4bd34be0d2e3%3A0x95ca80608ea1652e!2zQmV0IFlvc3NlZiBIYWltINeR15nXqiDXmdeV16HXoyDXl9eZ15nXnQ!5e0!3m2!1sfr!2sil!4v1634074342605!5m2!1sfr!2sil"
                width="200"
                height="150"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
