import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { crmService } from "../services/crmService";
import { confirmAlert } from "react-confirm-alert";
import {
  loadCrms,
  removeCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import { Modal2 } from "./Modal2";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { saveUser } from "../store/actions/userAction";
import { DashboardHeaderAffectation } from "../cmp/DashboardHeaderAffectation";

export function GestionAffectation() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  const users = useSelector((state) => state.userReducer.users);
  const filter = useSelector((state) => state.userReducer.filter);

  const userIdxEdited = useSelector((state) => state.userReducer.userIdxEdited);
  // const crmsSoldDeb = useSelector((state) => state.crmReducer.crmsSoldDeb);
  // const crmsSoldCred = useSelector((state) => state.crmReducer.crmsSoldCred);
  // const crmUSoldDeb = useSelector((state) => state.crmReducer.crmUSoldDeb);
  // const crmUSoldCred = useSelector((state) => state.crmReducer.crmUSoldCred);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [openFlag, setOpenFlag] = useState(false);
  //const [crmId, setCrmId] = useState("");

  // let soldeTotalDebit2 = "";
  // let soldeTotalCredit2 = "";

  useEffect(() => {
    // setCrmId("");
    // dispatch(setEditCrm(0));
    //dispatch(setEditCrmuSoldDeb(0));
    // dispatch(setEditCrmuSoldCred(0));
    eventBus.on("editing", () => {
      setOpenFlag(false);
    });
    // soldeTotalDebit2 = "0";
    // soldeTotalCredit2 = "0";
    //console.log("toto");
    dispatch(loadCrms());
  }, [openFlag]);

  function verfAffect(crmid) {
    const user = users.find((el) => el._id === userIdxEdited);

    // console.log("crmid ", crmid);
    return user.crmId.includes(crmid);
  }

  function changeStatusAffect(crmid) {
    const user = users.find((el) => el._id === userIdxEdited);
    if (user.crmId.includes(crmid)) {
      // console.log("user.crmid1 ", user.crmId);
      user.crmId.splice(user.crmId.indexOf(crmid), 1);
      // console.log("user.crmid2 ", user.crmId);
    } else {
      user.crmId.push(crmid);
    }

    dispatch(saveUser(user));
  }

  function Tridata() {
    //const crms2 = crms.saisie.slice();
    crms.sort((a, b) => {
      const first = a.lastName;
      const second = b.lastName;
      return first > second ? 1 : first < second ? -1 : 0;
    });
  }

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  function calcSoldCred(crm) {
    let res = "";
    let resd = "";
    let resc = "";
    crm.saisie.forEach((el) => {
      resd = (Number(resd) + Number(el.debit)).toString();
    });
    crm.saisie.forEach((el) => {
      resc = (Number(resc) + Number(el.credit)).toString();
    });
    if (Number(resc) > Number(resd)) {
      res = (Number(resc) - Number(resd)).toString();
    } else {
      res = "0";
    }

    // soldeTotalCredit2 = (Number(soldeTotalCredit2) + Number(res)).toString();
    // dispatch(setEditCrmSoldCred(soldeTotalCredit2));
    return res;
  }
  function calcSoldDeb(crm) {
    let res = "";
    let resd = "";
    let resc = "";
    crm.saisie.forEach((el) => {
      resd = (Number(resd) + Number(el.debit)).toString();
    });
    crm.saisie.forEach((el) => {
      resc = (Number(resc) + Number(el.credit)).toString();
    });
    if (Number(resc) < Number(resd)) {
      res = (Number(resd) - Number(resc)).toString();
    } else {
      res = "0";
    }
    // soldeTotalDebit2 = (Number(soldeTotalDebit2) + Number(res)).toString();
    // dispatch(setEditCrmSoldDeb(soldeTotalDebit2));
    return res;
  }
  /*
  const onAdd = () => {
    setOpenFlag(true);
  };
*/
  if (!crms || !loggedInUser || !loggedInUser.isApprouved)
    return (
      <div>
        <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
        <h3>
          {" "}
          vous n'avez pas encore été approuvé par le responsable de
          l'application, patience ...{" "}
        </h3>
      </div>
    );
  return (
    <div>
      <DashboardHeaderAffectation />

      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>Nom </th>
            <th>prenom </th>
            <th>Telephone </th>

            <th>Solde Debit (nis) </th>
            <th>Solde Credit (nis) </th>
            <th>statut affectation </th>
          </tr>
        </thead>
        <TableBody className="table-body">
          {Tridata()}
          {crms.map((crm) => (
            <StyledTableRow key={crm._id}>
              <StyledTableCell className="partner">
                {crm.lastName}
              </StyledTableCell>

              <StyledTableCell className="partner">
                {crm.firstName}
              </StyledTableCell>
              <StyledTableCell className="partner">
                {crm.telephone}
              </StyledTableCell>

              <StyledTableCell className="partner">
                {calcSoldDeb(crm)}
              </StyledTableCell>

              <StyledTableCell className="partner">
                {calcSoldCred(crm)}
              </StyledTableCell>
              <StyledTableCell
                className="partner"
                onClick={
                  loggedInUser.isManager && (() => changeStatusAffect(crm._id))
                }
              >
                {verfAffect(crm._id) ? "Affecté" : "     "}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
