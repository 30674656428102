import { userService } from "../../services/userService";

export function setFilter(filter) {
  return (dispatch) => {
    dispatch({ type: "SET_FILTER", filter });
  };
}

export function clearFilter() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_FILTER" });
  };
}

export function setEditUser(userIdxEdited) {
  return async (dispatch) => {
    dispatch({ type: "SET_USERIDXEDITED", userIdxEdited });
  };
}

export function loadUsers(filterBy) {
  return async (dispatch) => {
    const users = await userService.query(filterBy);
    dispatch({ type: "SET_USERS", users });
  };
}
export function loadAdmin() {
  return async (dispatch) => {
    const users = await userService.getAdmin();
    dispatch({ type: "SET_USERS", users });
  };
}
export function removeUser(userId) {
  return async (dispatch) => {
    await userService.remove(userId);
    dispatch({ type: "REMOVE_USER", userId });
  };
}

export function saveUser(user) {
  return async (dispatch) => {
    const actionType = user._id ? "UPDATE_USER" : "ADD_USER";
    user = await userService.save(user);
    dispatch({ type: actionType, user });
  };
}

export function login(userCreds) {
  return async (dispatch) => {
    const user = await userService.login(userCreds);
    // console.log(user);
    dispatch({ type: "SET_USER", user });
    // window.location.assign("/#/");
  };
}
export function sendPassword(userCreds) {
  return async (dispatch) => {
    // console.log("totooo");

    const user = await userService.sendPassword(userCreds);
    // console.log(user);
    // dispatch({ type: "SET_USER", user });
    // window.location.assign("/#/");
  };
}
export async function sendEmail(msg) {
  // console.log("totooo");

  // return async (dispatch) => {
  // console.log(msg);

  const user = await userService.sendEmail(msg);
  //console.log("user", user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // };
}
export function sendCheckMail(userCreds) {
  return async (dispatch) => {
    // console.log("totooo");
    const user = await userService.sendCheckMail(userCreds);
    // console.log(user);
    // dispatch({ type: "SET_USER", user });
    // window.location.assign("/#/");
    return user;
  };
}
export async function keepParasha2() {
  //return async (dispatch) => {
  // console.log("totooo");
  const data = await userService.keepParasha();
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);
  return data;
  //};
}
export async function keepKiddush2() {
  //return async (dispatch) => {
  // console.log("totooo");
  const data = await userService.keepKiddush();
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);
  return data;
  //};
}
export async function keepShlishit2() {
  //return async (dispatch) => {
  // console.log("totooo");
  const data = await userService.keepShlishit();
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);
  return data;
  //};
}
export async function keepName(userCreds) {
  //return async (dispatch) => {
  // console.log("totooo");
  const data = await userService.getById(userCreds);
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);
  return data.firstName + " " + data.lastName;
  //};
}
export async function keepMaftir2() {
  //return async (dispatch) => {
  // console.log("totooo");
  const data = await userService.keepMaftir();
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);
  return data;
  //};
}
export async function keepInfHome() {
  //return async (dispatch) => {
  // console.log("totooo");
  const data = await userService.keepInfHome();
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);
  return data;
  //};
}
export async function sendParasha2(data) {
  //return async (dispatch) => {
  // console.log("totooo");
  userService.sendParasha(data);
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);

  //};
}

export async function sendMaftir2(data) {
  //return async (dispatch) => {
  // console.log("totooo");
  userService.sendMaftir(data);
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);

  //};
}
export async function sendShlishit2(data) {
  //return async (dispatch) => {
  // console.log("totooo");
  userService.sendShlishit(data);
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);

  //};
}
export async function sendKiddush2(data) {
  //return async (dispatch) => {
  // console.log("totooo");
  userService.sendKiddush(data);
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
  // console.log("data useraction", data);

  //};
}
export function newPassword(userCreds) {
  return async (dispatch) => {
    const rep = await userService.changePassword(userCreds);
    //console.log("reppp", rep);
  };
  // console.log(user);
  // dispatch({ type: "SET_USER", user });
  // window.location.assign("/#/");
}

/*export const login = (userCreds) => {
  return async (dispatch) => {
    return {
     user = await userService.login(userCreds),
    dispatch({ type: "SET_USER", user });
    // window.location.assign("/#/");
  };}
};*/

export function signup(userCreds) {
  return async (dispatch) => {
    const user = await userService.signup(userCreds);
    dispatch({ type: "SET_USER", user });
  };
}

export function logout() {
  return async (dispatch) => {
    await userService.logout();
    dispatch({ type: "SET_USER", user: null });
    dispatch({ type: "SET_CRMS", crms: [] });
    dispatch({ type: "SET_USERS", users: [] });
    dispatch({ type: "SET_CRMSSCRED", crmsSoldCred: null });
    dispatch({ type: "SET_CRMSSDEB", crmsSoldDeb: null });
    dispatch({ type: "SET_CRMIDXEDITED", crmIdxEdited: null });
    dispatch({ type: "SET_USERIDXEDITED", userIdxEdited: null });
    dispatch({ type: "SET_CRMSCRED", crmUSoldCred: null });
    dispatch({ type: "SET_CRMSDEB", crmUSoldDeb: null });

    //window.location.assign("/#/");
  };
}
