import React, { useState, useEffect } from "react";
import { eventBus } from "../services/eventBusService";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../services/userService";

import { Button, Input } from "reactstrap";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { loadUsers, saveUser, setEditUser } from "../store/actions/userAction";

export function LineForm4({ closeModal, userId }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const users = useSelector((state) => state.userReducer.users);
  const [costumerFirstName, setCostumerFirstName] = useState("");
  const [costumerLastName, setCostumerLastName] = useState("");
  const [costumerTelephone, setcostumerTelephone] = useState("");
  const [costumerEmail, setcostumerEmail] = useState("");
  //const [costumerDateMaj, setcostumerDateMaj] = useState("");
  const [costumerId, setcostumerId] = useState("");
  const filter = useSelector((state) => state.userReducer.filter);

  const [focusIdx, setFocusIdx] = useState(0);

  useEffect(() => {
    if (userId) getDetailesByUserId();
  }, []);

  const getDetailesByUserId = async () => {
    const data = await userService.getById(userId);

    setCostumerFirstName(data.firstName);
    setCostumerLastName(data.lastName);
    setcostumerTelephone(data.telephone);
    setcostumerEmail(data.email);

    setcostumerId(data._id);
  };

  const onHandleInputChange = (ev) => {
    const { name, value } = ev.target;
    //  console.log(name, value);

    switch (name) {
      case "costumerFirstName":
        setCostumerFirstName(value);
        break;
      case "costumerLastName":
        setCostumerLastName(value);
        break;
      case "costumerTelephone":
        setcostumerTelephone(value);
        break;
      case "costumerEmail":
        setcostumerEmail(value);
        break;
      default:
        break;
    }
  };

  const onFormSubmit = async () => {
    users.map((el) => {
      if (el._id === costumerId) {
        el.firstName = costumerFirstName;
        el.lastName = costumerLastName;
        el.telephone = costumerTelephone;
        el.email = costumerEmail.toLowerCase().trim();
        //   console.log("el", el);
        dispatch(saveUser(el));
      }
    });
    dispatch(loadUsers(filter));
    dispatch(setEditUser(0));
    eventBus.emit("editing");
  };

  const onChangeFocus = (ev) => {
    const { name } = ev.target;

    setFocusIdx(1);
  };

  if (!loggedInUser) return <div>loading</div>;

  return (
    <div className="line-big-container">
      <div className="line-form-container flex">
        <div className="section-container flex-col box1">
          <Button
            color="info"
            className="clicked choose-box animation-on-hover btn1"
          >
            <h5>Utilisateur</h5>
          </Button>
          <hr className="focus-line" />
          <div className="inputs-card flex-col">
            <TextField
              label="Nom "
              className="uyu"
              onFocus={onChangeFocus}
              type="text"
              name="costumerLastName"
              onChange={onHandleInputChange}
              value={costumerLastName}
            />
            <TextField
              label="Prénom"
              onFocus={onChangeFocus}
              type="text"
              name="costumerFirstName"
              onChange={onHandleInputChange}
              value={costumerFirstName}
            />
            <TextField
              label="Numero de Telephone"
              onFocus={onChangeFocus}
              type="text"
              name="costumerTelephone"
              onChange={onHandleInputChange}
              value={costumerTelephone}
            />
            <TextField
              label="Email"
              onFocus={onChangeFocus}
              type="text"
              name="costumerEmail"
              onChange={onHandleInputChange}
              value={costumerEmail}
            />
            <div className="btn-modal-container">
              <Button
                className="btn-simple animation-on-hover close-modal-btn"
                color="danger"
                onClick={closeModal}
              >
                X
              </Button>
              <Button
                className="save-btn btn-simple animation-on-hover"
                color="success"
                variant="contained"
                onClick={onFormSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
