import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { eventBus } from "../services/eventBusService";
import { DashboardHeader } from "../cmp/DashboardHeader";
import { DashboardHeadercrm } from "../cmp/DashboardHeadercrm";
import { crmService } from "../services/crmService";
import { userService } from "../services/userService";
import { loadUsers, removeUser, saveUser } from "../store/actions/userAction";
import { confirmAlert } from "react-confirm-alert"; // Import
//import "react-confirm-alert/src/react-confirm-alert.css";
import {
  loadCrms,
  removeCrm,
  saveCrm,
  setEditCrm,
  setEditCrmSoldCred,
  setEditCrmSoldDeb,
  setEditCrmuSoldCred,
  setEditCrmuSoldDeb,
} from "../store/actions/crmAction";
import { Modal3 } from "./Modal3";
import classnames from "classnames";
import {
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { DashboardHeaderCollecteurDetail } from "../cmp/DashboardHeaderCollecteurDetail";
import { Modal5 } from "./Modal5CollecteurDetail";
import { DashboardHeaderFournisseursDetail } from "../cmp/DashboardHeaderFournisseursDetail";

export function GestionFournisseursDetail() {
  const dispatch = useDispatch();
  const crms = useSelector((state) => state.crmReducer.crms);
  const users = useSelector((state) => state.userReducer.users);
  const crmIdxEdited = useSelector((state) => state.crmReducer.crmIdxEdited);
  const userIdxEdited = useSelector((state) => state.userReducer.userIdxEdited);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [openFlag2, setOpenFlag2] = useState(false);
  const [openFlag3, setOpenFlag3] = useState(false);
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data4, setData4] = useState();
  const [keyData, setKeyData] = useState();

  let data3 = [];

  useEffect(async () => {
    await dispatch(loadUsers());
    // await dispatch(loadCrms());
    getDetailesByUserId();
  }, [openFlag2, openFlag3]);

  const getDetailesByUserId = async () => {
    users.map((user) => {
      if (user.isAdmin) {
        user.fournisseurs.map((four) => {
          if (four._id === userIdxEdited) {
            setData1(four.firstName);
            setData2(four.lastName);
          }
        });
      }
    });
    //  setData(await userService.getById(userIdxEdited));
  };
  function Tridata() {
    /* crms.map((crm) => {
      crm.saisie.map((don) => {
        if (don.destinataire_id === userIdxEdited) {
          don.from = crm.firstName + " " + crm.lastName;
          data3.push(don);
        }
      });
    });*/

    //let dswitch = "";
    users.map((user2) => {
      if (user2.isManager) {
        user2.decaissement.map((el) => {
          if (el.destinataire_id === userIdxEdited) {
            el.from = user2.firstName + " " + user2.lastName;
            data3.push(el);
          }
        });
      }
    });

    /* data.decaissement.map((el, index) => {
      el.indice = index;
      data3.push(el);
    });*/
    //console.log(data.decaissement);
    //data3.push(data.decaissement.slice());
    // console.log(data3);
    data3.sort((a, b) => {
      const first = b.dateEvent;
      const second = a.dateEvent;
      return first > second ? 1 : first < second ? -1 : 0;
    });

    const resd = calcDeb(data3);
    const resc = calcCred(data3);
    if (Number(resc) > Number(resd)) {
      dispatch(
        setEditCrmuSoldCred((Number(resc) - Number(resd)).toFixed(2).toString())
      );
      dispatch(setEditCrmuSoldDeb("0"));
    } else {
      dispatch(
        setEditCrmuSoldDeb((Number(resd) - Number(resc)).toFixed(2).toString())
      );
      dispatch(setEditCrmuSoldCred("0"));
    }
  }
  function calcDeb(crm) {
    let res = "";
    let b = 0;
    crm.forEach((el) => {
      b = 0;
      if (!isNaN(el.debit)) b = Number(el.debit);
      res = (Number(res) + b).toFixed(2).toString();
    });
    return res;
  }
  function calcCred(crm) {
    let res = "";
    let b = 0;
    crm.forEach((el) => {
      b = 0;
      if (!isNaN(el.credit)) b = Number(el.credit);
      res = (Number(res) + b).toFixed(2).toString();
    });
    return res;
  }

  useEffect(() => {
    eventBus.on("editing", () => {
      setOpenFlag2(false);
      setKeyData();
      // setCrmId("");
      //dispatch(setEditCrm(0));

      //window.location.reload();
    });
    // dispatch(loadCrms());
  }, [openFlag2]);

  /*table styleee*/
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    paper: {
      elevation: 50,
    },
  });

  const classes = useStyles();

  /*  const onDelete = (rowKey) => {
    confirmAlert({
      title: "confirmation de la suppression",
      message: "Cette action est irreversible, confirmez-vous la suppression ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            // data3.splice(rowKey, 1);
            data.decaissement.splice(rowKey, 1);
            // data.dateMaj = moment().format();
            await dispatch(saveUser(data));
            //Tridata();
            setOpenFlag3(true);
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    });
  };

  const onEdit = async (rowKey) => {
    //if (crmIdxEdited == rowId) {
    // alert("Someone else is editing this row, please try later");
    //return;
    //} else {
    //console.log('redux state idx',crmIdxEdited);
    //dispatch(setEditCrm(rowId));
    //  console.log("rowkey=", rowKey);
    setKeyData(rowKey);
    setData4(data.decaissement);
    //console.log("data3", data3);
    //console.log("keydata =", keyData);
    setOpenFlag2(true);
    // }
  };

  const onAdd = () => {
    setData4(data.decaissement);
    setOpenFlag2(true);
  };*/

  if (!loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;

  //const filteredCrm = getFilteredTable();
  return (
    <div>
      {/*  {openFlag2 && <Modal5 data3={data4} keyData={keyData} />}*/}
      <DashboardHeaderFournisseursDetail firstName={data1} lastName={data2} />

      {/* <TableContainer elevation={20} className='table-container' component={Paper}> */}
      <Table
        id="table"
        className={`table-container ${classes.table}`}
        aria-label="customized table"
      >
        <thead className="text-center">
          <tr>
            <th>evennement</th>
            <th>date </th>

            <th> payé au Foutnisseur (nis) </th>
            <th> reçu du fournisseur (nis) </th>
            <th> mode paiement </th>
            <th> destinataire / origine </th>

            {/*loggedInUser.isManager && <th></th>*/}
            {/*loggedInUser.isManager && <th></th>*/}
          </tr>
        </thead>
        <TableBody className="table-body">
          {Tridata()}
          {data3.map((don, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell className="partner">{don.event}</StyledTableCell>

              <StyledTableCell className="partner">
                {moment(don.dateEvent).format("DD-MM-YY")}
              </StyledTableCell>

              <StyledTableCell className="partner">{don.debit}</StyledTableCell>

              <StyledTableCell className="partner">
                {don.credit}
              </StyledTableCell>
              <StyledTableCell className="partner">{don.mode}</StyledTableCell>
              <StyledTableCell className="partner">
                {don.from ? don.from : don.destinataire}
              </StyledTableCell>
              {/*!don.from && (
                <StyledTableCell className="crud-icons">
                  <i
                    onClick={() => {
                      onEdit(don.indice);
                    }}
                    className="fas fa-eye"
                  ></i>
                </StyledTableCell>
                  )*/}
              {/*!don.from && (
                <StyledTableCell className="crud-icons">
                  <i
                    onClick={() => {
                      onDelete(don.indice);
                    }}
                    className="fas fa-trash-alt"
                  ></i>
                </StyledTableCell>
              )*/}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
